import moment from "moment";
import "moment-timezone";
import { CONFIG } from "../config";

const tz = moment.tz.guess();

export function formatDate(date) {
  //date should be a string, check it has a length
  if (!date || !date.length) return "";

  //check if local storage has a date_format, if not use the default form CONFIG.defaultDateFormat
  let dateFormat =
    localStorage.getItem("date_format") !== null
      ? localStorage.getItem("date_format")
      : CONFIG.defaultDateFormat;

  let timeFormat =
    localStorage.getItem("time_format") !== null
      ? localStorage.getItem("time_format")
      : "HH:mm";

  // return the date in the local timezone
  return moment
    .utc(date)
    .tz(tz)
    .format(timeFormat + " " + dateFormat);
}

export function formatDateOnly(date) {
  if (!date || !date.length) return "";

  let timeFormat =
    localStorage.getItem("time_format") !== null
      ? localStorage.getItem("time_format")
      : "HH:mm";

  // return the date in the local timezone using the format HH:MM in 24 hour time
  return moment.utc(date).tz(tz).format(timeFormat);
}
