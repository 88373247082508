import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function ButtonPressGraph(props) {
  const [buttonPressHistory, setButtonPressHistory] = React.useState([]);

  const { alarm, useXlSize } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length === 0) {
      return;
    }

    try {
      let tempHistory = [];
      deviceHistory &&
        deviceHistory.forEach((dataObject) => {
          let jsonData = dataObject.data;
          jsonData = JSON.parse(jsonData);
          let time_created = dataObject.time_created;
          let button_pressed = jsonData.button_pressed;

          tempHistory.push({
            Date: time_created,
            ButtonPress: button_pressed === 1 ? "Yes" : "No",
          });
        });
      setButtonPressHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={alarm ? "Alarm History" : "Button Press History"} />
      <CardContent>
        <ResponsiveContainer aspect={!useXlSize ? (isMobile ? 2 : 10) : 5}>
          <AreaChart
            data={buttonPressHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="ButtonPress" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="ButtonPress" type="category" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              formatter={(value, name) => {
                alarm
                  ? (name = "Alarm Triggered?")
                  : (name = "Button Pressed?");
                return [value, name];
              }}
              labelFormatter={(value) => {
                return formatDate(value);
              }}
            />
            <Area
              yAxisId="1"
              dataKey="ButtonPress"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#ButtonPress)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
