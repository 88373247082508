import { store } from "../store";

export default function toggleSearchDialog() {
  store.dispatch({
    type: "search/update",
    payload: {
      value: !store.getState().search.value,
    },
  });
}
