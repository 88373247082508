import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { formatDate } from "../../Functions/formatDate";
import { useNavigate } from "react-router-dom";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import moment from "moment";
import formatTemperature from "../../Functions/formatTemperature";
import DeviceCardPanel from "../deviceCardPanel";
import convertPercentToColour from "../../Functions/convertPercentToColour";
import DeviceTypeAvatar from "../DeviceTypeAvatar";
import { useSelector } from "react-redux";

export default function DeviceCard(props) {
  const { device } = props;
  const [deviceFrames] = useState(
    //json decode from device.last_data
    device.last_data ? JSON.parse(device.last_data) : null
  );

  let voltageMv =
    deviceFrames && deviceFrames.voltageMv
      ? deviceFrames.voltageMv
      : deviceFrames && deviceFrames.batteryMv
      ? deviceFrames.batteryMv
      : deviceFrames && deviceFrames.bat
      ? deviceFrames.bat
      : 0;

  const isMobile = useSelector((state) => state.isMobile);

  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} xxl={4} sx={{ mb: 1 }}>
      <>
        <Card
          sx={{
            cursor: "pointer",
            pl: 1,
            pr: 1,
            height: "100%",
          }}
          onClick={
            () => navigate(`/device/${device.id}`)
            //onhover set shadow
          }
        >
          <CardHeader
            avatar={<DeviceTypeAvatar device={device} />}
            action={
              //if devicettype is 18, set hasBattery to false
              device && device.device_type && device.device_type !== 19 ? (
                <Tooltip
                  title={
                    deviceFrames
                      ? deviceFrames.voltageMv
                        ? "Battery: " +
                          convertMvToPercent(
                            deviceFrames.voltageMv,
                            device.device_brand
                          ) +
                          "%"
                        : "Battery: N/A"
                      : "Battery: N/A"
                  }
                  position="top"
                  arrow
                >
                  <Typography
                    sx={{
                      color: convertPercentToColour(
                        convertMvToPercent(voltageMv, device.device_brand)
                      ),
                      fontSize: "1.5rem",
                    }}
                    variant="p"
                  >
                    {convertMvToPercent(voltageMv, device.device_brand) + "%"}
                  </Typography>
                </Tooltip>
              ) : null
            }
            title={device.name}
            titleTypographyProps={
              //bigger font
              {
                fontSize: "1rem",
              }
            }
            subheader={
              device.time_updated
                ? formatDate(device.time_updated)
                : "No Data Yet..."
            }
            subheaderTypographyProps={{
              color: "textSecondary",
            }}
            sx={{ p: 1, pb: 2 }}
          />
          <Grid container spacing={1}>
            {device.device_type === 1 && (
              // GPS Tracker
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 2 && (
              // Air Quality
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 3 && (
              // Temperature, Humidity
              <>
                <Grid item xs={6} md={6}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 4 && (
              // Tracker with button
              <>
                <Grid item xs={12} md={10}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Alarm"
                    content={
                      deviceFrames &&
                      deviceFrames.button_pressed &&
                      deviceFrames.button_pressed === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 5 && (
              //distance sensor
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 6 && (
              //motion sensor
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Motion"
                    content={deviceFrames && deviceFrames.motion ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Time"
                    content={
                      deviceFrames && deviceFrames.motion
                        ? formatDate(
                            moment(device.time_updated)
                              .subtract(deviceFrames.time, "seconds") //subtract time from time_updated
                              .format()
                          )
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 7 && (
              <>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 8 && (
              //paxcounter
              <>
                {
                  //if ble and wifi, md=3, else if not ble and wifi, md=6, else if ble and not wifi, md=6
                }
                <Grid
                  item
                  xs={12}
                  md={
                    //if deviceFrames contains wifi and ble, md=3 else if deviceFrames contains wifi and not ble, md=6 else if deviceFrames contains ble and not wifi, md=6 if none of the above, md=10
                    deviceFrames && deviceFrames.wifi && deviceFrames.ble
                      ? 4
                      : deviceFrames && deviceFrames.wifi && !deviceFrames.ble
                      ? 8
                      : deviceFrames && deviceFrames.ble && !deviceFrames.wifi
                      ? 8
                      : 12
                  }
                >
                  <DeviceCardPanel
                    title="Total"
                    content={
                      deviceFrames
                        ? deviceFrames.count
                          ? deviceFrames.count
                          : "N/A"
                        : "N/A"
                    }
                  />
                </Grid>
                {deviceFrames && deviceFrames.ble ? (
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="BLE"
                      content={
                        deviceFrames && deviceFrames.ble
                          ? deviceFrames.ble
                          : "N/A"
                      }
                    />
                  </Grid>
                ) : null}
                {deviceFrames && deviceFrames.wifi ? (
                  <Grid item xs={12} md={4}>
                    <DeviceCardPanel
                      title="WiFi"
                      content={
                        deviceFrames && deviceFrames.wifi
                          ? deviceFrames.wifi
                          : "N/A"
                      }
                    />
                  </Grid>
                ) : null}
              </>
            )}
            {device.device_type === 9 && (
              //temperature 1 and 2
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature 1"
                    content={
                      deviceFrames && deviceFrames.temperature_1
                        ? deviceFrames.temperature_1 + "°C"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Temperature 2"
                    content={
                      deviceFrames && deviceFrames.temperature_2
                        ? deviceFrames.temperature_2 + "°C"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 10 && (
              //motion sensor
              <>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Motion"
                    content={deviceFrames && deviceFrames.motion ? "Yes" : "No"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DeviceCardPanel
                    title="Time"
                    content={
                      deviceFrames && deviceFrames.motion
                        ? formatDate(
                            moment(device.time_updated)
                              .subtract(deviceFrames.time, "seconds")
                              .format()
                          )
                        : "N/A"
                    }
                  />
                </Grid>
                {
                  //occupancy count
                }
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <Tooltip
                    sx={{ height: "100%" }}
                    title={
                      deviceFrames &&
                      deviceFrames.count &&
                      deviceFrames.count > 0
                        ? "Occupancy Count: " + deviceFrames.count
                        : "Occupancy Count: 0"
                    }
                    position="top"
                    arrow
                  >
                    <Card
                      sx={{
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#1f1f1f" : "#FAFAFA",
                        p: 2,
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        Count
                      </Typography>
                      <Typography variant="p" sx={{ fontSize: "1rem" }}>
                        {deviceFrames &&
                        deviceFrames.count &&
                        deviceFrames.count > 0
                          ? deviceFrames.count
                          : "0"}
                      </Typography>
                    </Card>
                  </Tooltip>
                </Grid>
              </>
            )}
            {device.device_type === 11 && (
              //Door/Window Sensor
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Open?"
                    content={
                      deviceFrames &&
                      deviceFrames.door_open &&
                      deviceFrames.door_open === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>

                {
                  //open count
                }
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Count"
                    content={
                      deviceFrames &&
                      deviceFrames.count &&
                      deviceFrames.count > 0
                        ? deviceFrames.count
                        : "0"
                    }
                  />
                </Grid>
                {
                  //temperature
                }
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 12 && (
              //Water Leak Sensor
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Leak?"
                    content={
                      deviceFrames &&
                      deviceFrames.water_leak &&
                      deviceFrames.water_leak === 1
                        ? "Yes"
                        : "No"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 1 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 13 && (
              //Air Quality Sensor with tvoc, co2, temperature, humidity
              <>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <DeviceCardPanel
                    title="TVOC"
                    content={
                      deviceFrames && deviceFrames.tvoc
                        ? deviceFrames.tvoc + "ppb"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temp"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 14 && (
              //pet tracker,show location and isLost (true/false)
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Lost?"
                    content={
                      deviceFrames && deviceFrames.isLost
                        ? "Yes"
                        : deviceFrames && deviceFrames.isLost === false
                        ? "No"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 15 && (
              // Oyster3
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 16 && (
              // Oyster3
              <>
                <Grid item xs={12} md={12}>
                  <DeviceCardPanel
                    title="Location"
                    content={
                      deviceFrames && deviceFrames.geocode
                        ? deviceFrames.geocode
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 17 && (
              // Cayenne LLP
              <>
                <Grid item xs={12}>
                  <DeviceCardPanel
                    title="Cayenne LLP"
                    content={
                      deviceFrames ? JSON.stringify(deviceFrames) : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 18 && (
              //tektelic vivid
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temperature
                        ? formatTemperature(deviceFrames.temperature)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.humidity
                        ? deviceFrames.humidity + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="C02"
                    content={
                      deviceFrames && deviceFrames.co2
                        ? deviceFrames.co2 + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 19 && (
              /*sensecore Kraken*/
              <>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Has Flow?"
                    content={
                      //check deviceFrames has a HasFlowRate field, if it doesnt return "N/A", else if it does, check if it is true or false, if true return "Yes", if false return "No"
                      deviceFrames && deviceFrames.HasFlowRate !== undefined
                        ? deviceFrames.HasFlowRate
                          ? "Yes"
                          : "No"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Current Flow"
                    content={
                      deviceFrames &&
                      deviceFrames.instantaneousFlow !== undefined
                        ? deviceFrames.instantaneousFlow
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Current Velocity"
                    content={
                      deviceFrames &&
                      deviceFrames.instantaneousVelocity !== undefined
                        ? deviceFrames.instantaneousVelocity
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Total Usage"
                    content={
                      deviceFrames && deviceFrames.positiveCumulative
                        ? deviceFrames.positiveCumulative
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 20 && (
              /*distance sensor 2*/
              <>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Distance"
                    content={
                      deviceFrames && deviceFrames.distance !== undefined
                        ? deviceFrames.distance + "mm"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DeviceCardPanel
                    title="Rssi"
                    content={
                      deviceFrames && deviceFrames.rssi !== undefined
                        ? deviceFrames.rssi
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <DeviceCardPanel
                    title="Hotspot Name"
                    content={
                      deviceFrames && deviceFrames.hotspot_name !== undefined
                        ? deviceFrames.hotspot_name
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 21 && (
              /*power meter*/
              <>
                {
                  //current, voltage, watts
                }
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Current"
                    content={
                      deviceFrames && deviceFrames.current
                        ? deviceFrames.current + "A"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Voltage"
                    content={
                      deviceFrames && deviceFrames.voltage
                        ? deviceFrames.voltage + "V"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <DeviceCardPanel
                    title="Watts"
                    content={
                      deviceFrames && deviceFrames.watt
                        ? deviceFrames.watt + "W"
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
            {device.device_type === 22 && (
              <Grid item xs={12} md={12}>
                <DeviceCardPanel
                  title="Leak?"
                  content={
                    deviceFrames &&
                    deviceFrames.water_leak &&
                    deviceFrames.water_leak === 1
                      ? "Yes"
                      : "No"
                  }
                />
              </Grid>
            )}
            {device.device_type === 23 && (
              <>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Temperature"
                    content={
                      deviceFrames && deviceFrames.temp
                        ? formatTemperature(deviceFrames.temp)
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="Humidity"
                    content={
                      deviceFrames && deviceFrames.RH
                        ? deviceFrames.RH + "%"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="CO2"
                    content={
                      deviceFrames &&
                      deviceFrames.CO2 &&
                      deviceFrames.CO2.length
                        ? deviceFrames.CO2[0] + "ppm"
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <DeviceCardPanel
                    title="TVOC"
                    content={
                      deviceFrames &&
                      deviceFrames.TVOC &&
                      deviceFrames.TVOC.length
                        ? deviceFrames.TVOC[0]
                        : "N/A"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <DeviceCardPanel
                    title="PM1.0"
                    content={
                      deviceFrames &&
                      deviceFrames.PM1_0 &&
                      deviceFrames.PM1_0.length
                        ? deviceFrames.PM1_0[0]
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={1}>
                  <DeviceCardPanel
                    title="PM2.5"
                    content={
                      deviceFrames &&
                      deviceFrames.PM2_5 &&
                      deviceFrames.PM2_5.length
                        ? deviceFrames.PM2_5[0]
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={1}>
                  <DeviceCardPanel
                    title="PM10"
                    content={
                      deviceFrames &&
                      deviceFrames.PM10 &&
                      deviceFrames.PM10.length
                        ? deviceFrames.PM10[0]
                        : "N/A"
                    }
                  />
                </Grid>

                <Grid item xs={12} md={1}>
                  <DeviceCardPanel
                    title="AQI"
                    content={
                      deviceFrames &&
                      deviceFrames.AQI &&
                      deviceFrames.AQI.length
                        ? deviceFrames.AQI[0]
                        : "N/A"
                    }
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </>
    </Grid>
  );
}
