import React from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function WaterLeakDetectedCard(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [leak, setLeak] = React.useState(false);

  const theme = useTheme();

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      if (props.field) {
        jsonData = jsonData[props.field];
      } else {
        jsonData.water_leak === 1 ? setLeak(true) : setLeak(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Leak Detected?
          </Typography>
        </Box>
        <Tooltip title={leak ? `Leak Detected: Yes` : `Leak Detected: No`}>
          <Typography
            variant="p"
            sx={{ fontSize: "1.2rem" }}
            color={leak ? "error" : "primary"}
            className="cursor-pointer"
          >
            {leak ? "Yes" : "No"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
