import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import {
  AddAlarm,
  CorporateFare,
  Fence,
  Logout,
  Person,
  Router,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import setSelectedOrganisation from "../Functions/setSelectedOrganisation";
import refreshData from "../Functions/refreshData";

function SidebarLayout(props) {
  const navigate = useNavigate();

  const { logout } = useAuth0();

  const { user } = useAuth0();

  const organisations = useSelector((state) => state.organisations);

  let theme = useTheme();

  const { collapsed } = props;

  const { t } = useTranslation("common");

  return (
    <Sidebar
      collapsed={collapsed}
      className="app"
      backgroundColor={
        theme.palette.mode === "dark" ? "#191B1F" : theme.palette.primary.main
      }
    >
      <Menu
        menuItemStyles={{
          button: ({ level, active, disabled }) => {
            if (level === 0) {
              return {
                color: "white",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "#191B1F"
                    : theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.main
                      : "white",
                },
              };
            } else if (level === 1) {
              return {
                color: "white",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "#3b4049"
                    : theme.palette.secondary.main,
                "&:hover": {
                  backgroundColor: theme.palette.secondary.main,
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.primary.main
                      : "white",
                },
              };
            }
          },
        }}
      >
        <Tooltip title={t("common.devices")} placement="right">
          <MenuItem onClick={() => navigate("/")} icon={<Router />}>
            {t("common.devices")}
          </MenuItem>
        </Tooltip>
        <Tooltip title={t("common.rules")} placement="right">
          <SubMenu label={t("common.rules")} icon={<AddAlarm />}>
            <MenuItem onClick={() => navigate("/action")} icon={<AddAlarm />}>
              {t("common.actions")}
            </MenuItem>
            <MenuItem onClick={() => navigate("/geofence")} icon={<Fence />}>
              {t("common.geofences")}
            </MenuItem>
          </SubMenu>
        </Tooltip>

        <Tooltip title={t("common.contacts")} placement="right">
          <MenuItem onClick={() => navigate("/contact")} icon={<Person />}>
            {t("common.contacts")}
          </MenuItem>
        </Tooltip>
        <Tooltip title={t("common.organisations")} placement="right">
          <SubMenu label={t("common.organisations")} icon={<CorporateFare />}>
            <MenuItem onClick={() => navigate("/organisation")}>
              {t("common.manage_organisations")}
            </MenuItem>

            {organisations && organisations.length
              ? organisations.map((organisation) => {
                  return (
                    <MenuItem
                      sx={{
                        color:
                          theme.palette.mode === "dark" ? "#8ba1b7" : "#fff",
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "#191B1F"
                            : theme.palette.primary.main,
                      }}
                      value={organisation.id}
                      onClick={() => {
                        setSelectedOrganisation(organisation);
                        localStorage.setItem(
                          "selectedOrganisationID",
                          organisation.id
                        );
                        refreshData(user.email, user.sub);
                        navigate("/");
                      }}
                      key={organisation.id}
                    >
                      {organisation.name}
                    </MenuItem>
                  );
                })
              : null}
          </SubMenu>
        </Tooltip>
        <Tooltip title={t("common.settings")} placement="right">
          <MenuItem onClick={() => navigate("/account")} icon={<Settings />}>
            {t("common.account")}
          </MenuItem>
        </Tooltip>

        <Tooltip title={t("common.logout")} placement="right">
          <MenuItem
            onClick={() => logout({ returnTo: window.location.origin })}
            icon={<Logout />}
          >
            {t("common.logout")}
          </MenuItem>
        </Tooltip>
      </Menu>
    </Sidebar>
  );
}

export default SidebarLayout;
