import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { formatDate } from "../../Functions/formatDate";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function LastSeen(props) {
  const [last_heard, setLastHeard] = React.useState("N/A");
  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);
  useEffect(() => {
    if (deviceHistory && deviceHistory.length > 0) {
      setLastHeard(deviceHistory[deviceHistory.length - 1].time_created);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Last Heard
          </Typography>
        </Box>
        <Tooltip
          title={
            last_heard !== "N/A"
              ? "Last Heard: " + formatDate(last_heard)
              : "Last Heard: N/A"
          }
        >
          <Typography variant="p" sx={{ fontSize: "1.2rem" }}>
            {last_heard !== "N/A" ? formatDate(last_heard) : "N/A"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
