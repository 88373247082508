import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import OrganisationSettingsDialog from "../Dialogs/organisationSettings";
import LeaveOrganisationDialog from "../Dialogs/leaveOrganisation";
import setSelectedOrganisation from "../../Functions/setSelectedOrganisation";
import { fetchOrganisations } from "../../Functions/fetchOrganisations";
import { useSelector } from "react-redux";
import refreshData from "../../Functions/refreshData";
import { useAuth0 } from "@auth0/auth0-react";

export default function OrganisationCard(props) {
  const { organisation } = props;

  const [openOrganisationSettingsDialog, setOpenOrganisationSettingsDialog] =
    useState(false);
  const [leaveOrganisationDialogOpen, setLeaveOrganisationDialogOpen] =
    useState(false);
  const [editOrganisation, setEditOrganisation] = useState(null);

  const userDB = useSelector((state) => state.user);

  const { user } = useAuth0();

  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} md={12} lg={6} xl={4} sx={{ mb: 1 }}>
        <Card
          sx={{
            //onhover set shadow
            "&:hover": {
              boxShadow: 10,
            },
            cursor: "pointer",
          }}
        >
          <CardHeader
            title={organisation.name}
            titleTypographyProps={
              //bigger font
              {
                variant: "h4",
              }
            }
            action={
              //pill button to view organisation
              <Tooltip title={"View Organisation"} placement="top" arrow>
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    setSelectedOrganisation(organisation);
                    localStorage.setItem(
                      "selectedOrganisationID",
                      organisation.id
                    );
                    refreshData(user.email, user.sub);

                    navigate(`/`);
                  }}
                  // add arrow right icon to end of button
                >
                  <ArrowForward />
                </Button>
              </Tooltip>
            }
          />
          <CardContent>
            <Typography
              variant="body1"
              color={theme.palette.mode === "dark" ? "white" : "black"}
            >
              {organisation.description}
            </Typography>
          </CardContent>
          <CardActions>
            {
              // if owner_id === userDB_id show management options
              userDB && organisation.owner_id === userDB.id && (
                <>
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    onClick={() =>
                      navigate(`/organisation/${organisation.id}/members`)
                    }
                  >
                    Members
                  </Button>
                  {/*
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    onClick={() =>
                      navigate(`/organisation/${organisation.id}/billing`)
                    }
                  >
                    Billing
                  </Button>
                  */}
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    onClick={() => {
                      setEditOrganisation(organisation);
                      setOpenOrganisationSettingsDialog(true);
                    }}
                  >
                    Settings
                  </Button>
                </>
              )
            }
            {
              //if organisation is not owned by userDB show leave organisation
              userDB && userDB.id && organisation.owner_id !== userDB.id && (
                <Button
                  variant="outlined"
                  color={
                    theme.palette.mode === "dark" ? "primary" : "secondary"
                  }
                  onClick={() => {
                    setLeaveOrganisationDialogOpen(true);
                  }}
                >
                  Leave Organisation
                </Button>
              )
            }
          </CardActions>
        </Card>
      </Grid>
      <OrganisationSettingsDialog
        open={openOrganisationSettingsDialog}
        handleClose={() => {
          setOpenOrganisationSettingsDialog(false);
          fetchOrganisations();
        }}
        organisation={editOrganisation}
      />
      <LeaveOrganisationDialog
        open={leaveOrganisationDialogOpen}
        onClose={() => {
          setLeaveOrganisationDialogOpen(false);
          fetchOrganisations();
        }}
        organisation={organisation}
      />
    </>
  );
}
