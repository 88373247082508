import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { postData } from "../../API/Post";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import isTracker from "../../Functions/isTracker";

export default function DeviceSettings(props) {
  const { open, onClose } = props;
  const { t } = useTranslation("common");
  const [showTemperature, setShowTemperature] = useState(false);
  const [showHumidity, setShowHumidity] = useState(false);
  const [showC02, setShowC02] = useState(false);
  const [showTracker, setShowTracker] = useState(false);
  const [showDistance, setShowDistance] = useState(false);
  const [editedDeviceProfile, setEditedDeviceProfile] = useState({});

  const device = useSelector((state) => state.device);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  useEffect(() => {
    if (isTracker(device.device_type)) {
      setShowTracker(true);
      if (Object.keys(deviceProfile).length !== 0) {
        try {
          let deviceProfileParsed = JSON.parse(deviceProfile);
          setEditedDeviceProfile(deviceProfileParsed);
        } catch (error) {
          return;
        }
      } else {
        setEditedDeviceProfile({
          tracker: {
            route_matching_type: "none",
            animated_lines: false,
          },
        });
      }
    }

    if (device.device_type && device.device_type === 2) {
      setShowC02(true);
      setShowTemperature(true);
      setShowHumidity(true);
      if (Object.keys(deviceProfile).length !== 0) {
        try {
          let deviceProfileParsed = JSON.parse(deviceProfile);
          setEditedDeviceProfile(deviceProfileParsed);
        } catch (error) {
          return;
        }
      } else {
        setEditedDeviceProfile({
          temperature: {
            min: -20,
            max: 60,
            safe_range_start: 10,
            safe_range_end: 30,
          },
          humidity: {
            min: 0,
            max: 100,
            safe_range_start: 30,
            safe_range_end: 70,
          },
          c02: {
            min: 0,
            max: 4000,
            safe_range_start: 0,
            safe_range_end: 1000,
          },
        });
      }
    }

    if (device.device_type && device.device_type === 3) {
      setShowTemperature(true);
      setShowHumidity(true);
      if (Object.keys(deviceProfile).length !== 0) {
        try {
          let deviceProfileParsed = JSON.parse(deviceProfile);
          setEditedDeviceProfile(deviceProfileParsed);
        } catch (error) {
          return;
        }
      } else {
        setEditedDeviceProfile({
          temperature: {
            min: -20,
            max: 60,
            safe_range_start: 10,
            safe_range_end: 30,
          },
          humidity: {
            min: 0,
            max: 100,
            safe_range_start: 30,
            safe_range_end: 70,
          },
        });
      }
    }

    if (device.device_type && device.device_type === 5) {
      setShowDistance(true);
      if (Object.keys(deviceProfile).length !== 0) {
        try {
          let deviceProfileParsed = JSON.parse(deviceProfile);
          setEditedDeviceProfile(deviceProfileParsed);
        } catch (error) {
          return;
        }
      } else {
        setEditedDeviceProfile({
          distance: {
            min: 0,
            max: 100,
            safe_range_start: 25,
            safe_range_end: 75,
          },
        });
      }
    }

    if (device.device_type && device.device_type === 6) {
      setShowTemperature(true);
      setShowHumidity(true);
      if (Object.keys(deviceProfile).length !== 0) {
        try {
          let deviceProfileParsed = JSON.parse(deviceProfile);
          setEditedDeviceProfile(deviceProfileParsed);
        } catch (error) {
          return;
        }
      } else {
        setEditedDeviceProfile({
          temperature: {
            min: -20,
            max: 60,
            safe_range_start: 10,
            safe_range_end: 30,
          },
          humidity: {
            min: 0,
            max: 100,
            safe_range_start: 30,
            safe_range_end: 70,
          },
        });
      }
    }
  }, [device, open, deviceProfile]);

  const handleSave = async () => {
    let deviceProfileCleaned = editedDeviceProfile;

    console.log("deviceProfileCleaned", deviceProfileCleaned);

    //make sure no safe_range_start is less than min
    if (deviceProfileCleaned.temperature) {
      if (
        deviceProfileCleaned.temperature.safe_range_start <
        deviceProfileCleaned.temperature.min
      ) {
        deviceProfileCleaned.temperature.min =
          deviceProfileCleaned.temperature.safe_range_start;
      }
    }
    if (deviceProfileCleaned.humidity) {
      if (
        deviceProfileCleaned.humidity.safe_range_start <
        deviceProfileCleaned.humidity.min
      ) {
        deviceProfileCleaned.humidity.min =
          deviceProfileCleaned.humidity.safe_range_start;
      }
    }
    if (deviceProfileCleaned.c02) {
      if (
        deviceProfileCleaned.c02.safe_range_start < deviceProfileCleaned.c02.min
      ) {
        deviceProfileCleaned.c02.min =
          deviceProfileCleaned.c02.safe_range_start;
      }
    }
    if (deviceProfileCleaned.distance) {
      if (
        deviceProfileCleaned.distance.safe_range_start <
        deviceProfileCleaned.distance.min
      ) {
        deviceProfileCleaned.distance.min =
          deviceProfileCleaned.distance.safe_range_start;
      }
    }

    //make sure no safe_range_end is greater than max
    if (deviceProfileCleaned.temperature) {
      if (
        deviceProfileCleaned.temperature.safe_range_end >
        deviceProfileCleaned.temperature.max
      ) {
        deviceProfileCleaned.temperature.max =
          deviceProfileCleaned.temperature.safe_range_end;
      }
    }
    if (deviceProfileCleaned.humidity) {
      if (
        deviceProfileCleaned.humidity.safe_range_end >
        deviceProfileCleaned.humidity.max
      ) {
        deviceProfileCleaned.humidity.max =
          deviceProfileCleaned.humidity.safe_range_end;
      }
    }
    if (deviceProfileCleaned.c02) {
      if (
        deviceProfileCleaned.c02.safe_range_end > deviceProfileCleaned.c02.max
      ) {
        deviceProfileCleaned.c02.max = deviceProfileCleaned.c02.safe_range_end;
      }
    }
    if (deviceProfileCleaned.distance) {
      if (
        deviceProfileCleaned.distance.safe_range_end >
        deviceProfileCleaned.distance.max
      ) {
        deviceProfileCleaned.distance.max =
          deviceProfileCleaned.distance.safe_range_end;
      }
    }

    //make sure no safe_range_start is greater than safe_range_end
    if (deviceProfileCleaned.temperature) {
      if (
        deviceProfileCleaned.temperature.safe_range_start >
        deviceProfileCleaned.temperature.safe_range_end
      ) {
        deviceProfileCleaned.temperature.safe_range_end =
          deviceProfileCleaned.temperature.safe_range_start;
      }
    }
    if (deviceProfileCleaned.humidity) {
      if (
        deviceProfileCleaned.humidity.safe_range_start >
        deviceProfileCleaned.humidity.safe_range_end
      ) {
        deviceProfileCleaned.humidity.safe_range_end =
          deviceProfileCleaned.humidity.safe_range_start;
      }
    }
    if (deviceProfileCleaned.c02) {
      if (
        deviceProfileCleaned.c02.safe_range_start >
        deviceProfileCleaned.c02.safe_range_end
      ) {
        deviceProfileCleaned.c02.safe_range_end =
          deviceProfileCleaned.c02.safe_range_start;
      }
    }
    if (deviceProfileCleaned.distance) {
      if (
        deviceProfileCleaned.distance.safe_range_start >
        deviceProfileCleaned.distance.safe_range_end
      ) {
        deviceProfileCleaned.distance.safe_range_end =
          deviceProfileCleaned.distance.safe_range_start;
      }
    }

    let data = {
      //json dump into a string
      profile: JSON.stringify(deviceProfileCleaned),
      device_id: device.id,
    };
    postData("/device/" + device.id + "/profile", data);
    updateSnackbarMessage(t("common.device_settings_saved"));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Device Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5">Dev EUI</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">
                {device.dev_eui ? device.dev_eui : ""}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={1}>
            {showTracker && (
              <>
                <Grid item xs={12} md={12}>
                  {
                    // show route matching type driving, walking, cycling
                  }
                  <TextField
                    label="Route Matching Type"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                    value={
                      editedDeviceProfile.tracker &&
                      editedDeviceProfile.tracker.route_matching_type
                        ? editedDeviceProfile.tracker.route_matching_type
                        : "none"
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        tracker: {
                          ...editedDeviceProfile.tracker,
                          route_matching_type: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={"none"}>None</MenuItem>
                    <MenuItem value={"driving"}>Driving</MenuItem>
                    <MenuItem value={"walking"}>Walking</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  {
                    // show animated lines or not
                  }
                  <TextField
                    label="Animated Lines"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    select
                    value={
                      editedDeviceProfile &&
                      editedDeviceProfile.tracker &&
                      editedDeviceProfile.tracker.animated_lines
                        ? editedDeviceProfile.tracker.animated_lines
                        : false
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        tracker: {
                          ...editedDeviceProfile.tracker,
                          animated_lines: e.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </TextField>
                </Grid>
              </>
            )}

            {showTemperature && (
              <>
                <Grid item xs={12}>
                  <Box sx={{ my: 2 }}>
                    <Typography variant="h6">Temperature Settings</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Min"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°C</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile &&
                      editedDeviceProfile.temperature &&
                      editedDeviceProfile.temperature.min
                        ? editedDeviceProfile.temperature.min
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        temperature: {
                          ...editedDeviceProfile.temperature,
                          min: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Max"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°C</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.temperature &&
                      editedDeviceProfile.temperature.max
                        ? editedDeviceProfile.temperature.max
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        temperature: {
                          ...editedDeviceProfile.temperature,
                          max: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range Start"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°C</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.temperature &&
                      editedDeviceProfile.temperature.safe_range_start
                        ? editedDeviceProfile.temperature.safe_range_start
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        temperature: {
                          ...editedDeviceProfile.temperature,
                          safe_range_start: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range End"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">°C</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.temperature &&
                      editedDeviceProfile.temperature.safe_range_end
                        ? editedDeviceProfile.temperature.safe_range_end
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        temperature: {
                          ...editedDeviceProfile.temperature,
                          safe_range_end: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
              </>
            )}
            {showHumidity && (
              <>
                <Grid item xs={12}>
                  <Box sx={{ my: 2 }}>
                    <Typography variant="h6">Humidity Settings</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  {
                    //show min max and safe range
                  }
                  <TextField
                    label="Min"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.humidity &&
                      editedDeviceProfile.humidity.min
                        ? editedDeviceProfile.humidity.min
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        humidity: {
                          ...editedDeviceProfile.humidity,
                          min: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Max"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.humidity &&
                      editedDeviceProfile.humidity.max
                        ? editedDeviceProfile.humidity.max
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        humidity: {
                          ...editedDeviceProfile.humidity,
                          max: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range Start"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={
                      editedDeviceProfile.humidity &&
                      editedDeviceProfile.humidity.safe_range_start
                        ? editedDeviceProfile.humidity.safe_range_start
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        humidity: {
                          ...editedDeviceProfile.humidity,
                          safe_range_start: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range End"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.humidity &&
                      editedDeviceProfile.humidity.safe_range_end
                        ? editedDeviceProfile.humidity.safe_range_end
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        humidity: {
                          ...editedDeviceProfile.humidity,
                          safe_range_end: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
              </>
            )}
            {showC02 && (
              <>
                <Grid item xs={12}>
                  <Box sx={{ my: 2 }}>
                    <Typography variant="h6">CO2 Settings</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Min"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ppm</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.c02 && editedDeviceProfile.c02.min
                        ? editedDeviceProfile.c02.min
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        c02: {
                          ...editedDeviceProfile.c02,
                          min: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Max"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ppm</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.c02 && editedDeviceProfile.c02.max
                        ? editedDeviceProfile.c02.max
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        c02: {
                          ...editedDeviceProfile.c02,
                          max: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range Start"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ppm</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.c02 &&
                      editedDeviceProfile.c02.safe_range_start
                        ? editedDeviceProfile.c02.safe_range_start
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        c02: {
                          ...editedDeviceProfile.c02,
                          safe_range_start: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range End"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">ppm</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.c02 &&
                      editedDeviceProfile.c02.safe_range_end
                        ? editedDeviceProfile.c02.safe_range_end
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        c02: {
                          ...editedDeviceProfile.c02,
                          safe_range_end: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
              </>
            )}
            {showDistance && (
              <>
                <Grid item xs={12}>
                  <Box sx={{ my: 2 }}>
                    <Typography variant="h6">Distance Settings</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Full"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.distance &&
                      editedDeviceProfile.distance.min
                        ? editedDeviceProfile.distance.min
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        distance: {
                          ...editedDeviceProfile.distance,
                          min: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Empty"
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">mm</InputAdornment>
                      ),
                    }}
                    fullWidth
                    value={
                      editedDeviceProfile.distance &&
                      editedDeviceProfile.distance.max
                        ? editedDeviceProfile.distance.max
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        distance: {
                          ...editedDeviceProfile.distance,
                          max: parseFloat(e.target.value ? e.target.value : 0),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range Start"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.distance &&
                      editedDeviceProfile.distance.safe_range_start
                        ? editedDeviceProfile.distance.safe_range_start
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        distance: {
                          ...editedDeviceProfile.distance,
                          safe_range_start: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <TextField
                    label="Safe Range End"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={
                      editedDeviceProfile.distance &&
                      editedDeviceProfile.distance.safe_range_end
                        ? editedDeviceProfile.distance.safe_range_end
                        : 0
                    }
                    onChange={(e) => {
                      setEditedDeviceProfile({
                        ...editedDeviceProfile,
                        distance: {
                          ...editedDeviceProfile.distance,
                          safe_range_end: parseFloat(
                            e.target.value ? e.target.value : 0
                          ),
                        },
                      });
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t("common.close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSave()}
          color="primary"
        >
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
