import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function SpeedAndHeadingCard(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [speed, setSpeed] = React.useState(0);
  const [heading, setHeading] = React.useState(0);

  let unit = localStorage.getItem("speed_unit") || "kmph";

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      let speedKmph = jsonData.speedKmph;

      if (unit === "mph") {
        speedKmph = speedKmph * 0.621371;
      }

      //round speed to 2 decimal places
      speedKmph = Math.round(speedKmph * 100) / 100;

      setSpeed(speedKmph || 0);
      setHeading(jsonData.headingDeg || 0);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <>
      <Card sx={{ mb: 1, height: "50%" }}>
        <CardHeader title={"Speed"} />
        <CardContent>
          <Typography sx={{ mb: 1 }} variant="h3">
            {speed + " " + unit}
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 1, height: "auto" }}>
        <CardHeader title={"Heading"} />
        <CardContent>
          <Typography sx={{ mb: 1 }} variant="h3">
            {heading + "\u00b0"}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
