import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function PMGuague(props) {
  const [pmValue, setPmValue] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  let defaultArch = [
    {
      //good, green
      limit: 12,
      color: "#1f883d",
    },
    {
      //satisfactory, light green
      limit: 35.4,
      color: "#14e34f",
    },
    {
      //moderate, yellow
      limit: 55.4,
      color: "#FFFF00",
    },
    {
      //poor, orange
      limit: 150.4,
      color: "#FFC300",
    },
    {
      //very poor red
      limit: 500,
      //dark red
      color: "#C70039",
    },
  ];

  const { value } = props;

  //replace any _ with . in props.value
  let title = props.value.replace(/_/g, ".");

  if (value === "PM2_5") {
    defaultArch = [
      {
        //good, green
        limit: 12,
        color: "#1f883d",
      },
      {
        //satisfactory, light green
        limit: 35.4,
        color: "#14e34f",
      },
      {
        //moderate, yellow
        limit: 55.4,
        color: "#FFFF00",
      },
      {
        //poor, orange
        limit: 150.4,
        color: "#FFA500",
      },
      {
        //very poor red
        limit: 350.4,
        //dark red
        color: "#EE4B2B",
      },
      {
        //very poor red
        limit: 500,
        //dark red
        color: "#800020",
      },
    ];
  } else if (value === "PM10") {
    defaultArch = [
      {
        //good, green
        limit: 54,
        color: "#1f883d",
      },
      {
        //satisfactory, light green
        limit: 154,
        color: "#14e34f",
      },
      {
        //moderate, yellow
        limit: 254,
        color: "#FFFF00",
      },
      {
        //poor, orange
        limit: 354,
        color: "#FFA500",
      },
      {
        //very poor red
        limit: 504,
        //dark red
        color: "#EE4B2B",
      },
      {
        //very poor red
        limit: 604,
        //dark red
        color: "#800020",
      },
    ];
  }

  let unit = "ug/m3";

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      console.log("value is: ", jsonData[value][0]);

      setPmValue(jsonData[value][0]);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, deviceProfile]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={title}
        action={<Typography variant="h5">{pmValue}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
          tickLabels: {
            type: "outer",
          },
        }}
        value={pmValue}
        minValue={0}
        maxValue={defaultArch[defaultArch.length - 1].limit}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax type={value} unit={unit} />
      </Box>
    </Card>
  );
}
