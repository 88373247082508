import React, { useEffect, useMemo, useRef, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import Layout from "./components/Layout";
import { CONFIG } from "./config";

import { useAuth0 } from "@auth0/auth0-react";
import AppRoutes from "./routes";
import { useSelector } from "react-redux";
import { updateIsMobile } from "./Functions/updateIsMobile";
import { updateThemeMode } from "./Functions/updateThemeMode";
import refreshData from "./Functions/refreshData";
import { updatePitch } from "./Functions/updatePitch";
import checkBilling from "./Functions/checkBilling";

function App() {
  const { getAccessTokenSilently } = useAuth0();
  const interval = useRef(null);

  let isShare = false;

  //if url contains share, set isShare to true
  if (window.location.href.includes("share")) {
    isShare = true;
  }

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const { user } = useAuth0();

  const themeMode = useSelector((state) => state.themeMode);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("sidebarCollapsed") === "true" ? true : false
  );
  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const theme = useMemo(
    () =>
      createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            ///xxl: apple studio display
            xxl: 2048,
          },
        },
        palette: {
          mode: themeMode === "dark" ? "dark" : "light",
          gradient:
            themeMode === "dark"
              ? "linear-gradient( to bottom, #191B1F 0%, #191B1F 550px, #25282D 550px, #25282D 150%)"
              : "linear-gradient( to bottom, " +
                CONFIG.primaryColour +
                " 0%, " +
                CONFIG.primaryColour +
                " 550px, #EFEFEF 550px , #EFEFEF 150%)",
          primary: {
            main: CONFIG.primaryColour,
          },
          secondary: {
            main: CONFIG.secondaryColour,
          },
        },
        typography: {
          fontFamily: "Plus Jakarta Sans",
        },
      }),
    [themeMode]
  );

  const handleThemeChange = (mode = null) => {
    if (mode !== null) {
      updateThemeMode(mode);
      localStorage.setItem("theme", mode);
      return;
    }

    if (localStorage.getItem("theme") === null) {
      updateThemeMode(CONFIG.defaultTheme === "light" ? "dark" : "light");
      localStorage.setItem(
        "theme",
        CONFIG.defaultTheme === "light" ? "dark" : "light"
      );
    } else if (localStorage.getItem("theme") === "light") {
      updateThemeMode("dark");
      localStorage.setItem("theme", "dark");
    } else {
      updateThemeMode("light");
      localStorage.setItem("theme", "light");
    }
  };

  const fetchToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      localStorage.setItem("access_token", accessToken);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    //get browser preffered dark mode
    const darkMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    //check if user has set dark mode
    const themeFromLocal = localStorage.getItem("theme");

    updateThemeMode(localStorage.getItem("theme"));

    //if darkmode & themeFromLocal is not set, set dark mode
    if (darkMode && !themeFromLocal) {
      updateThemeMode("dark");
    }

    //if window is less than 900px, set isMobile to true
    if (window.innerWidth < 900) {
      updateIsMobile(true);
    }

    updatePitch(parseInt(localStorage.getItem("pitch") || 0));

    //event listener to detect if mobile
    window.addEventListener("resize", () => {
      if (window.innerWidth < 900) {
        updateIsMobile(true);
      } else {
        updateIsMobile(false);
      }
    });
  }, []);

  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      refreshData(user.email, user.sub);
      // 2 minutes
    }, 120000);

    //add window resize event listener
    window.addEventListener("resize", () => {
      if (window.innerWidth < 900) {
        updateIsMobile(true);
      } else {
        updateIsMobile(false);
      }
    });
  }, [selectedOrganisation]);

  useEffect(() => {
    if (!user) return;
    checkBilling();
    fetchDataAndToken();
  }, [user]);

  const fetchDataAndToken = async () => {
    await fetchToken();
    refreshData(user.email, user.sub);
    //check billing on load
  };

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Layout
            handleThemeChange={handleThemeChange}
            handleCollapsed={handleCollapsed}
            collapsed={collapsed}
            isShare={isShare}
          >
            <AppRoutes
              collapsed={collapsed}
              handleThemeChange={handleThemeChange}
              isShare={isShare}
            />
          </Layout>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
