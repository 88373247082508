import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function LiquidLevelGraph(props) {
  const [distanceHistory, setDistanceHistory] = React.useState([]);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0 || !props.deviceProfile) {
      return;
    }

    try {
      //get min and max distance from deviceProfile
      let deviceProfile = JSON.parse(props.deviceProfile);
      let minDistance = deviceProfile.distance.min;
      let maxDistance = deviceProfile.distance.max;

      //grab all data objects from deviceHistory, parse them, and add them to distanceHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let distance = jsonData.distance;

        // use min as 100% and max as 0%
        let percentFull =
          100 - ((distance - minDistance) / (maxDistance - minDistance)) * 100;

        tempHistory.push({
          Date: time_created,
          fill_level: percentFull,
          distance: distance,
        });
      });
      setDistanceHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory, props.deviceProfile]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Fill Level History" />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 5 : 2}>
          <AreaChart
            data={distanceHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDate(value);
              }}
            />
            <YAxis dataKey="fill_level" type="number" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return (
                  <Typography variant="body1">{formatDate(label)}</Typography>
                );
              }}
              //format label to capitalize first letter of each word and format value to use celsius symbol and format date

              formatter={(value, name) => {
                return [value.toFixed(1) + "%", "Fill Level"];
              }}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="fill_level"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
