import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../../Cards/avgAndMax";
import formatTemperature from "../../../Functions/formatTemperature";
import { useSelector } from "react-redux";

export default function TemperatureGuague(props) {
  const tmp = localStorage.getItem("temperature_unit");

  let defaultArchC = [
    {
      limit: 0,
      color: "#0061f2",
    },
    {
      limit: 10,
      color: "#00c1ff",
    },
    {
      limit: 20,
      color: "#5BE12C",
    },
    {
      limit: 30,
      color: "#F5CD19",
    },
    {
      limit: 50,
      //dark red
      color: "#EA4228",
    },
  ];

  let defaultArchF = [
    {
      limit: 0,
      color: "#0061f2",
    },
    {
      limit: 30,
      color: "#00c1ff",
    },
    {
      limit: 50,
      color: "#5BE12C",
    },
    {
      limit: 80,
      color: "#F5CD19",
    },
    {
      limit: 100,
      //dark red
      color: "#EA4228",
    },
  ];

  const [temperature, setTemperature] = React.useState(0);
  const [defaultArch, setDefaultArch] = React.useState(
    tmp === "F" ? defaultArchF : defaultArchC
  );
  const [minValue, setMinValue] = React.useState(tmp === "F" ? 0 : -20);
  const [maxValue, setMaxValue] = React.useState(tmp === "F" ? 100 : 50);

  const { temperatureValue, title } = props;

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      //if temperatureValue is not null, set it to use temperature
      if (temperatureValue) {
        //find the key with matching name to temperatureValue
        let key = Object.keys(jsonData).find(
          (key) => key.toLowerCase() === temperatureValue.toLowerCase()
        );
        //if key is found, set temperature to value of key
        if (key) {
          setTemperature(jsonData[key]);
        }
      } else {
        //get temperature
        setTemperature(jsonData.temperature);
      }

      if (deviceProfile && deviceProfile.length) {
        let deviceProfileObj = JSON.parse(deviceProfile);

        let arch = [
          //use min and max
          {
            //convert to number
            limit: parseFloat(
              deviceProfileObj.temperature.safe_range_start - 1
            ),
            //red
            color: "#EA4228",
          },
          {
            limit: parseFloat(deviceProfileObj.temperature.safe_range_end),
            // GREEn
            color: "#5BE12C",
          },
          {
            //dark red
            color: "#EA4228",
          },
        ];

        setDefaultArch(arch);
        setMinValue(deviceProfileObj.temperature.min);
        setMaxValue(deviceProfileObj.temperature.max);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, deviceProfile]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title={title ? title : "Temperature"}
        action={
          <Typography variant="h5">{formatTemperature(temperature)}</Typography>
        }
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
          tickLabels: {
            type: "outer",
            valueConfig: {
              formatTextValue: (value) => formatTemperature(value),
              fontSize: 10,
            },
          },
        }}
        value={temperature}
        minValue={minValue}
        maxValue={maxValue}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax
          type="temperature"
          value={temperatureValue ? temperatureValue : "temperature"}
          unit={localStorage.getItem("temperature_unit") === "F" ? "°F" : "°C"}
        />
      </Box>
    </Card>
  );
}
