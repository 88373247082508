import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function WaterLeakGraph(props) {
  const [leakHistory, setLeakHistory] = React.useState([]);

  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    if (deviceHistory.length === 0) {
      return;
    }

    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let water_leak = jsonData.water_leak;

        if (props.field) {
          water_leak = jsonData[props.field];
        }

        tempHistory.push({
          Date: time_created,
          WaterLeak: water_leak === 1 ? "Yes" : "No",
        });
      });
      setLeakHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Water Leak History" />
      <CardContent>
        <ResponsiveContainer aspect={isMobile ? 2 : 10}>
          <AreaChart
            data={leakHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="WaterLeak" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="WaterLeak" type="category" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              formatter={(value, name) => {
                return [value, "Water Leak Detected"];
              }}
              labelFormatter={(value) => {
                return formatDate(value);
              }}
            />
            <Area
              yAxisId="1"
              dataKey="WaterLeak"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#WaterLeak)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
