import React from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import WaterUsageGuage from "../Guages/WaterUsage";

export default function HasFlow(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [hasFlow, setHasFlow] = React.useState(false);

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      console.log(jsonData.HasFlowRate);

      jsonData.HasFlowRate === true ? setHasFlow(true) : setHasFlow(false);
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title="Water Flowing?"
        action={<Typography variant="h5">{hasFlow ? "Yes" : "No"}</Typography>}
      />
      <CardContent sx={{ height: "100%" }}>
        <WaterUsageGuage period="now" />
      </CardContent>
    </Card>
  );
}
