import { Card, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function DeviceCardPanel(props) {
  const isMobile = useSelector((state) => state.isMobile);
  const { title, content } = props;
  const theme = useTheme();
  return (
    <Tooltip
      sx={{ height: "100%" }}
      title={title + ": " + content}
      position="top"
      arrow
    >
      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#FAFAFA",
          p: 1,
          mb: 1,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {title}
        </Typography>
        <Typography
          variant="p"
          noWrap={isMobile ? false : true}
          sx={{
            fontSize: "1rem",
            mt: 2,
            pr: 1,
            wordWrap: isMobile ? "break-word" : "normal",
          }}
        >
          {content}
        </Typography>
      </Card>
    </Tooltip>
  );
}
