import { Button, Grid, Tooltip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OrganisationCard from "../components/Cards/organisation";
import { useTheme } from "@emotion/react";
import { Add } from "@mui/icons-material";
import DialogOrganisationAdd from "../components/Dialogs/organisationAdd";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { fetchOrganisations } from "../Functions/fetchOrganisations";
import { useSelector } from "react-redux";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import checkBilling from "../Functions/checkBilling";

function Organisations() {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [addOrganisationDialogOpen, setAddOrganisationDialogOpen] =
    useState(false);

  const organisations = useSelector((state) => state.organisations);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      checkBilling();
      //set page title
      setTitle(t("organisations.name"));
    }

    return () => (mounted = false);
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
            <PageTitle theme={theme} title={t("organisations.name")} />
            <Tooltip title="Add a Organisation" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => setAddOrganisationDialogOpen(true)}
              >
                <Add />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>

        {organisations && organisations.length
          ? organisations.map((organisation) => {
              return (
                <OrganisationCard
                  key={organisation.id}
                  organisation={organisation}
                />
              );
            })
          : null}
      </Grid>

      <DialogOrganisationAdd
        open={addOrganisationDialogOpen}
        onClose={() => {
          fetchOrganisations();
          setAddOrganisationDialogOpen(false);
        }}
      />
    </>
  );
}

export default withAuthenticationRequired(Organisations);
