export default function cleanAddress(historyItem) {
  try {
    let data = historyItem.data;
    //json parse data
    let dataParsed = JSON.parse(data);

    let address = dataParsed.geocode;

    // split on commas
    let split = address.split(",");

    let addressCleaned =
      split[0] +
      " " +
      split[1] +
      ", " +
      split[2] +
      ", " +
      split[3] +
      ", " +
      split[4];

    if (split[6] !== undefined) {
      addressCleaned = addressCleaned + ", " + split[6];
    }

    return addressCleaned;
  } catch (e) {
    return "No geocode available";
  }
}
