import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function TVOCGuague(props) {
  const [tvoc, setTVOC] = React.useState(0);
  const [defaultArch, setDefaultArch] = React.useState([
    {
      limit: 1000,
      // green
      color: "#5BE12C",
    },
    {
      limit: 1500,
      //dark orange
      color: "#F5CD19",
    },
    {
      color: "#EA4228",
    },
  ]);
  const [minValue, setMinValue] = React.useState(0);
  const [maxValue, setMaxValue] = React.useState(2000);

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);
      //get TVOC
      let value = 0;

      if (!props.tvocValue) {
        value = jsonData.tvoc;
      } else {
        value = jsonData[props.tvocValue];
      }

      //if its an array, use the first value
      if (Array.isArray(value)) {
        value = value[0];
      }

      setTVOC(value);

      if (props.deviceProfile) {
        let deviceProfile = JSON.parse(props.deviceProfile);

        let arch = [
          //use min and max
          {
            limit: parseFloat(deviceProfile.tvoc.safe_range_start - 1),
            //red
            color: "#EA4228",
          },
          {
            limit: parseFloat(deviceProfile.tvoc.safe_range_end),
            // GREEn
            color: "#5BE12C",
          },
          {
            //dark red
            color: "#EA4228",
          },
        ];

        setDefaultArch(arch);
        setMinValue(deviceProfile.tvoc.min);
        setMaxValue(deviceProfile.tvoc.max);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, props.deviceProfile]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title="TVOC"
        action={<Typography variant="h5">{tvoc + "ppb"}</Typography>}
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
        }}
        value={tvoc}
        minValue={minValue}
        maxValue={maxValue}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax
          type="tvoc"
          value={props.tvocValue ? props.tvocValue : "tvoc"}
          unit="ppb"
        />
      </Box>
    </Card>
  );
}
