import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const PageTitle = ({ title, theme }) => {
  const isMobile = useSelector((state) => state.isMobile);
  return (
    <Typography
      variant="h4"
      component="p"
      color={theme.palette.mode === "dark" ? "primary" : "black"}
      sx={{ flexGrow: 1, fontSize: isMobile ? "1.4rem" : "2rem" }}
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
