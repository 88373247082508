import { Alert, Container, IconButton, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import NavBar from "./NavBar";
import { Close } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { handleSnackbarClose } from "../Functions/handleSnackbarClose";
import SidebarLayout from "./sidebar";
import SearchDialog from "./Dialogs/SearchDialog";

export default function Layout(props) {
  if (props.isFullScreen) {
    return props.children;
  }
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  const isMobile = useSelector((state) => state.isMobile);

  const snackbar = useSelector((state) => state.snackbar);
  const themeMode = useSelector((state) => state.themeMode);
  const search = useSelector((state) => state.search);

  useEffect(() => {
    setSnackbarOpen(snackbar.open);
    setSnackbarMessage(snackbar.message);
  }, [snackbar]);

  //if props.isShare return children
  if (props.isShare) {
    return props.children;
  }

  return (
    <>
      <NavBar
        handleThemeChange={props.handleThemeChange}
        handleCollapsed={props.handleCollapsed}
      />
      <div style={{ display: "flex", height: "100%", minHeight: "100vh" }}>
        {
          //if mobile, set sidebar to null
          isMobile ? null : <SidebarLayout collapsed={props.collapsed} />
        }

        <Container
          maxWidth="false"
          disableGutters
          sx={{
            p: 1,
            //if theme = dark, set bg to black
            //else set bg to white
            backgroundColor:
              themeMode && themeMode === "dark" ? "#000" : "#EFEFEF",
          }}
        >
          {props.children}
        </Container>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity ? snackbar.severity : "info"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <SearchDialog open={search.value ? search.value : false} />
    </>
  );
}
