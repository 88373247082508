import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Box,
  TextField,
  Card,
  CardContent,
  Grid,
  FormControl,
} from "@mui/material";
import getCardCaptureSession from "../Functions/getCardCaptureSession";
import { useTranslation } from "react-i18next";
import upgradeSubscription from "../Functions/upgradeSubscription";
import fetchData from "../API/Fetch";
import DataTableBase from "../components/DataTableBase";
import DeleteDevicesDialog from "../components/Dialogs/deleteDevices";
import { useSelector } from "react-redux";
import { formatDate } from "../Functions/formatDate";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import setTitle from "../Functions/setTitle";
import PageTitle from "../components/PageTitle";
import { useTheme } from "@emotion/react";

export default function LimitReached(props) {
  const { t } = useTranslation("common");
  const [limit, setLimit] = useState(0);

  const [devices, setDevices] = useState([]);

  const theme = useTheme();

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteDevicesDialogOpen, setDeleteDevicesDialogOpen] = useState(false);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "time_updated",
      label: "Last Seen",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return formatDate(value);
        },
      },
    },
  ];

  const [totalDevices, setTotalDevices] = useState(0);

  const user = useSelector((state) => state.user);

  const fetchTotalDevices = async () => {
    if (!user) return;
    if (!user.id) return;

    await fetchData(`/user/${user.id}/devices-total`).then((data) => {
      if (!data) return;

      let total = data.total;

      setLimit(user.device_limit > total ? user.device_limit : total);
      setTotalDevices(total);

      //if total is 0 or total matches limit, redirect to /
      if (total === 0 || total === user.device_limit) {
        window.location.href = "/";
      }
    });
  };

  const fetchDevices = async () => {
    let response = await fetchData(`/device/limit`);
    setDevices(response);
  };

  //limit useEffect if the new props.limit is less than the current amount of devices, set limit to the amount of devices
  useEffect(() => {
    setTitle("Device Limit Reached");
    fetchTotalDevices();
    fetchDevices();
  }, [user]);

  const upgradeLimit = async () => {
    if (limit < totalDevices) {
      //show error
      updateSnackbarMessage(t("common.limit_increase_lower_error"));
      return;
    } else {
      if (!user.stripe_customer_id) {
        //alert user a card capture session will be started
        alert(t("common.card_capture_session"));
        //get /card-capture-session from api
        let redirectURL = await getCardCaptureSession();
        //redirect to redirectURL
        window.location.href = redirectURL;
      } else {
        //call /upgrade-account from api

        let resp = await upgradeSubscription(limit);
        //check if resp has session_url
        if (resp.session_url) {
          //redirect to session_url
          window.location.href = resp.session_url;
        } else {
          //check if we have a status of 200
          if (resp.status === 200) {
            updateSnackbarMessage(t("common.limit_increase_success"));
            window.location.href = "/billing/subscription-success";
          } else {
            //show error
            updateSnackbarMessage(t("common.limit_increase_error"));
          }
        }
      }
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        //center everything
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={6} sx={{ mb: 1 }}>
          <Box sx={{ mb: 1 }}>
            <PageTitle title={t("common.limit_reached")} theme={theme} />
          </Box>
          <Box sx={{ mb: 1 }}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  {t("common.limit_reached_description")}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Current Limit: {user.device_limit}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Total Devices on Account: {totalDevices}
                </Typography>

                <Box sx={{ mb: 1 }}>
                  <FormControl>
                    <TextField
                      id="outlined-number"
                      label="New Device Limit"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={limit}
                      onChange={(e) => {
                        setLimit(e.target.value);
                      }}
                      sx={{
                        mt: 3,
                        mb: 3,
                      }}
                      //min 3
                      inputProps={{ min: totalDevices || 0 }}
                    />
                  </FormControl>

                  {
                    //show cost of upgrade limit - 3 * 10 GBP per year
                  }
                  <Typography variant="h5" sx={{ my: 2 }} gutterBottom>
                    {user.stripe_subscription_id
                      ? "Upgrade Cost: "
                      : "Total Cost Due Today: "}{" "}
                    £{(limit - user.device_limit) * 15} per year.
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    upgradeLimit();
                  }}
                >
                  {
                    // if user has a stripe_subscription_id, show upgrade otherwise show create
                    user.stripe_subscription_id ? "Upgrade" : "Create"
                  }{" "}
                  Subscription
                </Button>
              </CardContent>
            </Card>
          </Box>
          {
            //allow user to delete devices
          }
          <Box sx={{ mb: 1 }}>
            <PageTitle
              title={"Or remove devices to return to your limit"}
              theme={theme}
            />
          </Box>

          <DataTableBase
            title="Select Devices For Removal"
            columns={columns}
            data={devices}
            selectableRows={"multiple"}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            actions={[
              {
                name: "Delete",
                icon: "Delete",
                action: () => {
                  setDeleteDevicesDialogOpen(true);
                },
              },
            ]}
          />
        </Grid>
      </Grid>
      <DeleteDevicesDialog
        open={deleteDevicesDialogOpen}
        setOpen={setDeleteDevicesDialogOpen}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        fetchTotalDevices={fetchTotalDevices}
        handleClose={() => {
          setDeleteDevicesDialogOpen(false);
          //fetch devices
          fetchDevices();
          //fetch total devices
          fetchTotalDevices();
        }}
      />
    </>
  );
}
