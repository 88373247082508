import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function PMGraph(props) {
  const [valueHistory, setValueHistory] = React.useState([]);

  //replace any _ with . in props.value
  let title = props.value.replace(/_/g, ".");

  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let value = jsonData[props.value];

        //value is an array, only add the first
        if (Array.isArray(value)) {
          tempHistory.push({
            Date: time_created,
            Value: value[0],
          });
        } else {
          tempHistory.push({
            Date: time_created,
            Value: value,
          });
        }
      });
      setValueHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={title + " History"} />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 5 : 2}>
          <AreaChart
            data={valueHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="Value" type="number" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                return [value, name];
              }}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="Value"
              //use theme color for line
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
