import React, { useEffect, useState } from "react";
import { formatDate } from "../../Functions/formatDate";
import DataTableBase from "../DataTableBase";
import { useTranslation } from "react-i18next";
import { Grid, LinearProgress, Typography } from "@mui/material";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import formatTemperature from "../../Functions/formatTemperature";
import DeleteFramesDialog from "../Dialogs/DeleteFramesDialog";
import { useSelector } from "react-redux";
import removeSelectedFrames from "../../Functions/removeSelectedFrames";

export default function FrameList(props) {
  const { t } = useTranslation("common");

  const sharingShown = props.isShare ? true : false;

  const [deleteFramesDialogOpen, setDeleteFramesDialogOpen] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const device = useSelector((state) => state.device);
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const user = useSelector((state) => state.user);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const [columns, setColumns] = useState([
    {
      name: "fcnt",
      label: "Frame Count",
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "time_created",
      label: t("common.date"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "N/A") {
            return <Typography>N/A</Typography>;
          }

          return <Typography>{formatDate(value, "LLL")}</Typography>;
        },
      },
    },
    {
      //battery
      name: "data",
      label: t("common.battery"),
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let battery = 0;
          //get battery from data object
          try {
            let jsonData = value;
            jsonData = JSON.parse(jsonData);
            battery = jsonData.voltageMv || jsonData.batteryMv;
          } catch (error) {}

          if (battery === 0) {
            return <Typography>N/A</Typography>;
          }

          //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
          return (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <LinearProgress
                  variant="determinate"
                  value={convertMvToPercent(
                    battery,
                    device && device.device_brand ? device.device_brand : null
                  )}
                  max={100}
                  min={0}
                  sx={{ height: 6, borderRadius: "12px" }}
                  color={
                    convertMvToPercent(
                      battery,
                      device && device.device_brand ? device.device_brand : null
                    ) < 20
                      ? "error"
                      : convertMvToPercent(
                          battery,
                          device && device.device_brand
                            ? device.device_brand
                            : null
                        ) < 50
                      ? "warning"
                      : "primary"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" align="center">
                  {convertMvToPercent(
                    battery,
                    device && device.device_brand ? device.device_brand : null
                  )}
                  %
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ]);

  useEffect(() => {
    if (deviceHistory && deviceHistory.length) {
      if (deviceHistory.length === 0) {
        return;
      } else {
        //loop through deviceHistory contains event_status, temperature, light, add them to the columns
        let tempColumns = [
          {
            name: "fcnt",
            label: "Frame Count",
            options: {
              display: false,
              filter: false,
              sort: false,
            },
          },
          {
            name: "time_created",
            label: t("common.date"),
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (value === "N/A") {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{formatDate(value, "LLL")}</Typography>;
              },
            },
          },
        ];
        let hasTemp = false;
        let hasLight = false;
        let hasEventStatus = false;
        let hasSpeedKmph = false;
        let hasHeadingDeg = false;
        let hasLocation = false;
        let hasHumidity = false;
        let hasc02PPM = false;
        let hasLocationResolutionMethod = false;
        let hasMotion = false;
        let hasButtonPressed = false;
        let hasFlowRate = false;
        let hasPositiveCumulative = false;
        let hasInstantaneousVelocity = false;
        let hasInstantaneousFlow = false;
        let hasDistance = false;
        let hasPosition = false;
        let hasCurrent = false;
        let hasVoltage = false;
        let hasWatts = false;
        let hasSosEvent = false;
        let hasLeak = false;
        let hasTemp2 = false;
        let hasRH = false;
        let hasTVOC = false;
        let haspm1_0 = false;
        let haspm2_5 = false;
        let haspm10 = false;
        let hasAQI = false;

        //loop through deviceHistory and check if it contains temperature, light, event_status, speedKmph, headingDeg
        //if any row contains any of these, add them to the columns
        for (let i = 0; i < deviceHistory.length; i++) {
          let data = deviceHistory[i].data;
          data = JSON.parse(data);

          if (data.co2 !== undefined && data.co2 !== null) {
            hasc02PPM = true;
          }

          if (data.RH !== undefined && data.RH !== null) {
            hasRH = true;
          }

          if (data.TVOC !== undefined && data.TVOC !== null) {
            hasTVOC = true;
          }

          if (data.PM1_0 !== undefined && data.PM1_0 !== null) {
            haspm1_0 = true;
          }

          if (data.PM2_5 !== undefined && data.PM2_5 !== null) {
            haspm2_5 = true;
          }

          if (data.PM10 !== undefined && data.PM10 !== null) {
            haspm10 = true;
          }

          if (data.AQI !== undefined && data.AQI !== null) {
            hasAQI = true;
          }

          if (data.temperature !== undefined && data.temperature !== null) {
            hasTemp = true;
          }
          if (data.temp !== undefined && data.temp !== null) {
            hasTemp2 = true;
          }

          if (data.humidity !== undefined && data.humidity !== null) {
            hasHumidity = true;
          }

          if (data.light !== undefined && data.light !== null) {
            hasLight = true;
          }

          if (data.event_status !== undefined && data.event_status !== null) {
            hasEventStatus = true;
          }

          if (data.speedKmph !== undefined && data.speedKmph !== null) {
            hasSpeedKmph = true;
          }

          if (data.headingDeg !== undefined && data.headingDeg !== null) {
            hasHeadingDeg = true;
          }

          if (data.geocode !== undefined) {
            hasLocation = true;
          }

          if (data.location_resolution_method !== undefined) {
            hasLocationResolutionMethod = true;
          }

          if (data.motion !== undefined) {
            hasMotion = true;
          }

          if (data.button_pressed !== undefined) {
            hasButtonPressed = true;
          }

          if (data.HasFlowRate !== undefined) {
            hasFlowRate = true;
          }

          if (data.positiveCumulative !== undefined) {
            hasPositiveCumulative = true;
          }

          if (data.instantaneousVelocity !== undefined) {
            hasInstantaneousVelocity = true;
          }

          if (data.instantaneousFlow !== undefined) {
            hasInstantaneousFlow = true;
          }

          if (data.distance !== undefined) {
            hasDistance = true;
          }

          if (data.position !== undefined) {
            hasPosition = true;
          }

          if (data.current !== undefined) {
            hasCurrent = true;
          }

          if (data.voltage !== undefined) {
            hasVoltage = true;
          }

          if (data.watt !== undefined) {
            hasWatts = true;
          }

          if (data.sos_event !== undefined) {
            hasSosEvent = true;
          }

          if (data.leak !== undefined) {
            hasLeak = true;
          }

          if (data.RH !== undefined) {
            hasRH = true;
          }
        }

        //add columns
        if (hasLocation) {
          tempColumns.push({
            //location
            name: "data",
            label: t("devices.last_location"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get geocode from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let geocode = jsonData.geocode;
                if (geocode === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{geocode}</Typography>;
              },
            },
          });
        }

        if (hasLeak) {
          tempColumns.push({
            name: "data",
            label: "Leak",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get leak from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let leak = jsonData.leak;
                if (leak === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{leak ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasLocationResolutionMethod) {
          tempColumns.push({
            //location resolution method
            name: "data",
            label: t("devices.location_resolution_method"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get location_resolution_method from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let location_resolution_method =
                  jsonData.location_resolution_method;
                if (location_resolution_method === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{location_resolution_method}</Typography>;
              },
            },
          });
        }

        if (hasc02PPM) {
          tempColumns.push({
            name: "data",
            label: t("devices.c02_ppm"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let c02PPM = jsonData.co2;
                if (c02PPM === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{c02PPM} ppm</Typography>;
              },
            },
          });
        }

        if (hasTemp) {
          tempColumns.push({
            name: "data",
            label: t("devices.temperature"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature = jsonData.temperature;
                if (temperature === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return (
                  <Typography>{formatTemperature(temperature)}</Typography>
                );
              },
            },
          });
        }

        if (hasTemp2) {
          tempColumns.push({
            name: "data",
            label: "Temperature",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let temperature = jsonData.temp;
                if (temperature === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return (
                  <Typography>{formatTemperature(temperature)}</Typography>
                );
              },
            },
          });
        }

        if (hasHumidity) {
          tempColumns.push({
            name: "data",
            label: t("devices.humidity"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get temperature from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let humidity = jsonData.humidity;
                if (humidity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{humidity.toFixed(1)}%</Typography>;
              },
            },
          });
        }

        if (hasLight) {
          tempColumns.push({
            name: "data",
            label: t("devices.light"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get light from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let light = jsonData.light;
                if (light === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{light}</Typography>;
              },
            },
          });
        }

        if (hasEventStatus) {
          tempColumns.push({
            name: "data",
            label: t("devices.event_status"),
            options: {
              filter: false,
              sort: false,

              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get event_status from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let event_status = jsonData.event_status;
                if (event_status === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return (
                  <Typography>
                    {event_status === "" ? "No Event" : event_status}
                  </Typography>
                );
              },
            },
          });
        }

        if (hasSpeedKmph) {
          tempColumns.push({
            name: "data",
            label: t("devices.speed"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get speedKmph from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let speedKmph = jsonData.speedKmph;

                let unit = localStorage.getItem("speed_unit") || "kmph";

                if (unit === "mph") {
                  speedKmph = speedKmph * 0.621371;
                }

                //round speed to 2 decimal places
                speedKmph = Math.round(speedKmph * 100) / 100;

                if (speedKmph === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{speedKmph + " " + unit}</Typography>;
              },
            },
          });
        }

        if (hasHeadingDeg) {
          tempColumns.push({
            name: "data",
            label: t("devices.heading"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get headingDeg from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let headingDeg = jsonData.headingDeg;
                if (headingDeg === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{headingDeg}</Typography>;
              },
            },
          });
        }

        if (hasMotion) {
          tempColumns.push({
            name: "data",
            label: t("devices.motion"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get motion from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let motion = jsonData.motion;
                if (motion === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{motion ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasButtonPressed) {
          tempColumns.push({
            name: "data",
            label: t("devices.button_pressed"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                if (!value) return <Typography>N/A</Typography>;
                //get button_pressed from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let button_pressed = jsonData.button_pressed;
                if (button_pressed === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{button_pressed ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasFlowRate) {
          tempColumns.push({
            name: "data",
            label: t("Has Flow?"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //HasFlowRate true or false, 1 or 0 display Yes or No

                if (!value) return <Typography>N/A</Typography>;
                //get HasFlowRate from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let HasFlowRate = jsonData.HasFlowRate;
                if (HasFlowRate === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{HasFlowRate ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousFlow) {
          tempColumns.push({
            name: "data",
            label: "Flow",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get instantaneousFlow from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneousFlow = jsonData.instantaneousFlow;
                if (instantaneousFlow === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneousFlow}</Typography>;
              },
            },
          });
        }

        if (hasInstantaneousVelocity) {
          tempColumns.push({
            name: "data",
            label: "Velocity",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get instantaneousVelocity from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let instantaneousVelocity = jsonData.instantaneousVelocity;
                if (instantaneousVelocity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{instantaneousVelocity}</Typography>;
              },
            },
          });
        }

        if (hasPositiveCumulative) {
          tempColumns.push({
            name: "data",
            label: "Total Usage",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                //get positiveCumulative from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let positiveCumulative = jsonData.positiveCumulative;
                if (positiveCumulative === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{positiveCumulative}</Typography>;
              },
            },
          });
        }

        if (hasDistance) {
          tempColumns.push({
            name: "data",
            label: t("devices.distance"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get distance from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let distance = jsonData.distance;
                if (distance === undefined || distance === null) {
                  return <Typography>No Measurement</Typography>;
                }

                return <Typography>{distance}</Typography>;
              },
            },
          });
        }

        if (hasPosition) {
          tempColumns.push({
            name: "data",
            label: t("devices.position"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get position from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let position = jsonData.position;

                if (position === undefined) {
                  return <Typography>N/A</Typography>;
                }

                return <Typography>{position}</Typography>;
              },
            },
          });
        }

        if (hasCurrent) {
          tempColumns.push({
            name: "data",
            label: t("devices.current"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get current from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let current = jsonData.current;
                if (current === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{current}A</Typography>;
              },
            },
          });
        }

        if (hasVoltage) {
          tempColumns.push({
            name: "data",
            label: t("devices.voltage"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get voltage from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let voltage = jsonData.voltage;
                if (voltage === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{voltage}V</Typography>;
              },
            },
          });
        }

        if (hasWatts) {
          tempColumns.push({
            name: "data",
            label: t("devices.watts"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get watts from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let watts = jsonData.watt;
                if (watts === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{watts}W</Typography>;
              },
            },
          });
        }

        if (hasSosEvent) {
          tempColumns.push({
            name: "data",
            label: t("devices.sos_event"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get sos_event from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let sos_event = jsonData.sos_event;
                if (sos_event === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{sos_event ? "Yes" : "No"}</Typography>;
              },
            },
          });
        }

        if (hasRH) {
          tempColumns.push({
            name: "data",
            label: "Humidity",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get humidity from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let humidity = jsonData.RH;
                if (humidity === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{humidity.toFixed(1)}%</Typography>;
              },
            },
          });
        }

        if (hasTVOC) {
          tempColumns.push({
            name: "data",
            label: "TVOC",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get TVOC from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let tvoc = jsonData.TVOC;

                //if array, return last value
                if (Array.isArray(tvoc)) {
                  tvoc = tvoc[tvoc.length - 1];
                }

                if (tvoc === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{tvoc}</Typography>;
              },
            },
          });
        }

        if (haspm1_0) {
          tempColumns.push({
            name: "data",
            label: "PM1.0",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM1.0 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm1_0 = jsonData.PM1_0;

                //if array, return last value
                if (Array.isArray(pm1_0)) {
                  pm1_0 = pm1_0[pm1_0.length - 1];
                }

                if (pm1_0 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm1_0} ppm</Typography>;
              },
            },
          });
        }

        if (haspm2_5) {
          tempColumns.push({
            name: "data",
            label: "PM2.5",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM2.5 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm2_5 = jsonData.PM2_5;

                //if array, return last value
                if (Array.isArray(pm2_5)) {
                  pm2_5 = pm2_5[pm2_5.length - 1];
                }

                if (pm2_5 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm2_5} ppm</Typography>;
              },
            },
          });
        }

        if (haspm10) {
          tempColumns.push({
            name: "data",
            label: "PM10",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get PM10 from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let pm10 = jsonData.PM10;

                //if array, return last value
                if (Array.isArray(pm10)) {
                  pm10 = pm10[pm10.length - 1];
                }

                if (pm10 === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{pm10} ppm</Typography>;
              },
            },
          });
        }

        if (hasAQI) {
          tempColumns.push({
            name: "data",
            label: "AQI",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                //get AQI from data object
                let jsonData = value;
                jsonData = JSON.parse(jsonData);
                let aqi = jsonData.AQI;

                //if array, return last value
                if (Array.isArray(aqi)) {
                  aqi = aqi[aqi.length - 1];
                }

                if (aqi === undefined) {
                  return <Typography>N/A</Typography>;
                }
                return <Typography>{aqi}</Typography>;
              },
            },
          });
        }

        if (device && device.device_type && device.device_type !== 19) {
          tempColumns.push({
            //battery
            name: "data",
            label: t("common.battery"),
            options: {
              filter: false,
              sort: false,
              customBodyRender: (value) => {
                // Remove unused parameters 'tableMeta' and 'updateValue'
                let battery = 0;
                //get battery from data object
                try {
                  let jsonData = value;
                  jsonData = JSON.parse(jsonData);
                  console.log(jsonData);
                  battery =
                    jsonData.voltageMv || jsonData.batteryMv || jsonData.bat;
                } catch (error) {}

                if (battery === 0) {
                  return <Typography>N/A</Typography>;
                }

                //draw a progress bar with the value and color it red if below 20% and yellow if below 50% and green if above 50%
                return (
                  <Typography>
                    {convertMvToPercent(
                      battery,
                      device && device.device_brand ? device.device_brand : null
                    )}
                    %
                  </Typography>
                );
              },
            },
          });
        }

        //update columns
        setColumns(tempColumns);
      }
    }
  }, [deviceHistory]);

  const isOwner = () => {
    if (
      user &&
      selectedOrganisation &&
      user.id === selectedOrganisation.owner_id
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <DataTableBase
        title={"Frame History"}
        columns={columns}
        data={deviceHistory ? deviceHistory : []}
        rowsPerPage={10}
        sortOrder="time_created"
        sortDirection="desc"
        selectableRows={
          isOwner() ? (sharingShown ? "none" : "multiple") : "none"
        }
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        actions={
          sharingShown
            ? []
            : [
                {
                  name: "Delete",
                  icon: "Delete",
                  action: () => {
                    setDeleteFramesDialogOpen(true);
                  },
                },
              ]
        }
      />

      <DeleteFramesDialog
        open={deleteFramesDialogOpen}
        selectedRows={selectedRows}
        handleClose={() => {
          removeSelectedFrames(selectedRows);
          setSelectedRows([]);
          setDeleteFramesDialogOpen(false);
        }}
      />
    </>
  );
}
