import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function SpeedGraph(props) {
  const [speedHistory, setSpeedHistory] = React.useState([]);
  const [minSpeed, setMinSpeed] = React.useState(0);
  const [maxSpeed, setMaxSpeed] = React.useState(0);

  const theme = useTheme();

  let unit = localStorage.getItem("speed_unit") || "kmph";

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to speedHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let speedKmph = jsonData.speedKmph;

        if (speedKmph === undefined) {
          speedKmph = 0;
        }

        if (unit === "mph") {
          speedKmph = speedKmph * 0.621371;
        }

        //round speed to 2 decimal places
        speedKmph = Math.round(speedKmph * 100) / 100;

        tempHistory.push({
          Date: time_created,
          Speed: speedKmph,
        });

        //get min and max speed
        if (speedKmph < minSpeed) {
          setMinSpeed(speedKmph);
        } else if (speedKmph > maxSpeed) {
          setMaxSpeed(speedKmph);
        }
      });

      setSpeedHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title={"Speed History"} />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 8 : 2}>
          <AreaChart
            data={speedHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient id="Speed" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              dataKey="Speed"
              type="number"
              yAxisId="1"
              //set domain based on speed\History
              domain={[
                Math.floor(minSpeed) === 0 ? 0 : Math.floor(minSpeed) - 3,
                Math.ceil(maxSpeed) === 0 ? 0 : Math.ceil(maxSpeed) + 3,
              ]}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              //format label to capitalize first letter of each word and format value to use celsius symbol and format date

              formatter={(value, name) => {
                return [value, unit];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="Speed"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Speed)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
