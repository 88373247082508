import * as React from "react";
import ReactDOM from "react-dom";
import Map, {
  FullscreenControl,
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl";
import { useSelector } from "react-redux";
import { CONFIG } from "../../config";
import mapboxgl from "mapbox-gl";
import { updateCurrent } from "../../Functions/updateCurrent";
import getCleanedPolyline from "../../Functions/getCleanedPolyline";
import { IconButton } from "@mui/material";
import { FitScreen, MyLocation } from "@mui/icons-material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { updatePitch } from "../../Functions/updatePitch";
import { formatDate } from "../../Functions/formatDate";
import convertMvToPercent from "../../Functions/convertMvToPercent";

export default function DeviceMap() {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const deviceProfile = useSelector((state) => state.deviceProfile);
  const device = useSelector((state) => state.device);
  const current = useSelector((state) => state.current);
  const [currentData, setCurrentData] = React.useState(null);
  const mapRef = React.useRef(null);
  const [polylineHistory, setPolylineHistory] = React.useState([]);
  const [followTracker, setFollowTracker] = React.useState(false);
  const pitch = useSelector((state) => state.pitch);

  const handleMapLoad = (e) => {
    mapRef.current = e.target;
    //fit to bounds of device history
    if (polylineHistory.length) {
      let bounds = new mapboxgl.LngLatBounds();
      polylineHistory.forEach((point) => {
        bounds.extend(point);
      });

      mapRef.current.fitBounds(bounds, {
        padding: 50,
        animate: false,
      });
    }
    mapRef.current.addControl(pitchToggle, "top-left");
    mapRef.current.addControl(fitToHistoryToggle, "top-left");
    mapRef.current.addControl(followTrackerToggle, "top-left");
    mapRef.current.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });

    //if history is empty, add a popup to the center of the map with a message to the user

    if (deviceHistory.length === 0) {
      //get current map center
      let center = mapRef.current.getCenter();

      new mapboxgl.Popup()
        .setLngLat([center.lng, center.lat])
        .setHTML(
          "<p style='text-align:center'>No history available for selected date range</h1>"
        )
        .addTo(mapRef.current);
    }

    //when device-history-circles is clicked, show a popup with the data
    mapRef.current.on("click", "device-history-circles", (e) => {
      let coordinates = e.features[0].geometry.coordinates.slice();
      let description = e.features[0].properties.data;

      //desc should read time_created, geocode, battery

      description = `<h4>${formatDate(
        e.features[0].properties.time_created
      )}</h4>`;
      description += `<p>Location: ${e.features[0].properties.geocode}</p>`;
      description += `<p>Battery: ${convertMvToPercent(
        e.features[0].properties.battery,
        device.device_brand_id
      )}%</p>`;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapRef.current);
    });

    //when dot-point is clicked, show a popup with the data
    mapRef.current.on("click", "currentLocation", (e) => {
      let coordinates = e.features[0].geometry.coordinates.slice();
      let description = e.features[0].properties.data;

      //desc should read time_created, geocode, battery

      description = `<h4>${formatDate(
        e.features[0].properties.time_created
      )}</h4>`;
      description += `<p>Geocode: ${e.features[0].properties.geocode}</p>`;
      description += `<p>Battery: ${convertMvToPercent(
        e.features[0].properties.battery,
        device.device_brand_id
      )}%</p>`;

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(mapRef.current);
    });
  };

  const size = 200;

  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // When the layer is added to the map,
    // get the rendering context for the map canvas.
    onAdd: function () {
      const canvas = document.createElement("canvas");
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext("2d");
    },

    // Call once before every frame where the icon will be used.
    render: function () {
      const duration = 3000;
      const t = (performance.now() % duration) / duration;

      const radius = (size / 3) * 0.3;
      const outerRadius = (size / 3) * 0.7 * t + radius;
      const context = this.context;

      // Draw the outer circle.
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(${CONFIG.primaryColourRGB.r}, ${
        CONFIG.primaryColourRGB.g
      }, ${CONFIG.primaryColourRGB.b}, ${1 - t})`;
      context.fill();

      // Draw the inner circle.
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = CONFIG.primaryColour;
      context.strokeStyle = CONFIG.secondaryColour;
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // Update this image's data with data from the canvas.
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // Continuously repaint the map, resulting
      // in the smooth animation of the dot.
      mapRef.current.triggerRepaint();

      // Return `true` to let the map know that the image was updated.
      return true;
    },
  };

  const drawPolyline = async () => {
    let polyline = deviceHistory.map((point) => {
      let data = JSON.parse(point.data);
      return [data.longitude, data.latitude];
    });

    /*
    device profile:
    {"tracker":{"route_matching_type":"driving","animated_lines":false}}
  */

    if (deviceProfile) {
      //it might need json decoding, check
      if (typeof deviceProfile === "string") {
        let deviceProfileDecoded = JSON.parse(deviceProfile);

        if (!deviceProfileDecoded.tracker) return;
        if (!deviceProfileDecoded.tracker.route_matching_type) return;
        if (deviceProfileDecoded.tracker.route_matching_type !== "none") {
          polyline = await getCleanedPolyline(
            polyline,
            deviceProfileDecoded.tracker.route_matching_type
          );
        }
      }
    }
    setPolylineHistory(polyline);
  };

  const handleFollowTracker = () => {
    setFollowTracker(!followTracker);
    //fit to current data
    if (followTracker) {
      if (currentData) {
        mapRef.current.flyTo({
          center: [currentData.longitude, currentData.latitude],
          zoom: 16,
        });
      }
    }
  };

  const fitToHistory = () => {
    //fit to polylineHistory
    if (polylineHistory.length) {
      let bounds = new mapboxgl.LngLatBounds();
      polylineHistory.forEach((point) => {
        bounds.extend(point);
      });

      mapRef.current.fitBounds(bounds, {
        padding: 50,
        animate: true,
      });
    }
  };

  class FitToHistory {
    onAdd(map) {
      this._map = map;

      const iconButton = document.createElement("div");
      ReactDOM.render(
        <IconButton
          aria-label="fit to history"
          onClick={() => {
            setFollowTracker(false);
            fitToHistory();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FitScreen sx={{ color: "black" }} />
        </IconButton>,
        iconButton
      );

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(iconButton);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  class FollowTracker {
    onAdd(map) {
      this._map = map;
      const iconButton = document.createElement("div");
      ReactDOM.render(
        <IconButton
          color="black"
          onClick={() => {
            handleFollowTracker();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MyLocation sx={{ color: "black" }} />
        </IconButton>,
        iconButton
      );

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(iconButton);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  class PitchToggle {
    constructor({ bearing = 0, pitch = 75, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      let _this = this;

      this._btn = document.createElement("button");

      // get pitch and set button icon
      if (localStorage.getItem("pitch") === "0") {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        this._btn.title = "Toggle Pitch (3D)";
      } else {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        this._btn.title = "Toggle Pitch (2D)";
      }
      this._btn.type = "button";
      this._btn["aria-label"] = "Toggle Pitch";
      this._btn.onclick = function () {
        if (map.getPitch() === 0) {
          let options = { pitch: _this._pitch, bearing: _this._bearing };
          if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
            options.zoom = _this._minpitchzoom;
          }
          updateSnackbarMessage("3D View Enabled");
          updatePitch(75);
          //set pitch in local storage so it can be retrieved when the page is refreshed
          localStorage.setItem("pitch", 75);

          map.easeTo({ pitch: 75, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        } else {
          updatePitch(0);
          updateSnackbarMessage("2D View Enabled");
          localStorage.setItem("pitch", 0);

          map.easeTo({ pitch: 0, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        }
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const pitchToggle = new PitchToggle({
    bearing: 0,
    pitch: pitch,
    minpitchzoom: null,
  });
  const fitToHistoryToggle = new FitToHistory();
  const followTrackerToggle = new FollowTracker();

  React.useEffect(() => {
    drawPolyline();

    //if followTracker is true, fit to current data
    if (followTracker) {
      if (currentData) {
        mapRef.current.flyTo({
          center: [currentData.longitude, currentData.latitude],
          zoom: 16,
        });
      }
    }
  }, [deviceHistory, deviceProfile, currentData, followTracker]);

  React.useEffect(() => {
    //when current changes, decode deviceHistory[current].data and set the map center to the new coordinates

    //if current > deviceHistory.length, set current to -1
    if (current >= deviceHistory.length) {
      updateCurrent(deviceHistory.length - 1);
      return;
    }

    if (!deviceHistory[current]) return;
    let data = JSON.parse(deviceHistory[current].data);

    //append time_created to data
    data.time_created = deviceHistory[current].time_created;

    setCurrentData(data);

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [data.longitude, data.latitude],
        zoom: 16,
      });
    }
  }, [current, mapRef]);

  return (
    <Map
      mapboxApiAccessToken={CONFIG.mapboxAccessToken}
      mapStyle={CONFIG.mapStyle}
      onLoad={(e) => {
        handleMapLoad(e);
      }}
    >
      <Source
        id="device-history"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: deviceHistory.map((point) => {
            let data = JSON.parse(point.data);
            return {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [data.longitude, data.latitude],
              },
              properties: {
                geocode: data.geocode,
                time_created: point.time_created,
                battery: data.voltageMv,
              },
            };
          }),
        }}
      />

      <Source
        id="polylineHistory"
        type="geojson"
        data={{
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: polylineHistory,
          },
        }}
      />

      {currentData && (
        <>
          <Source
            id="dot-point"
            type="geojson"
            data={{
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [currentData.longitude, currentData.latitude],
                  },
                  properties: {
                    geocode: currentData.geocode,
                    time_created: currentData.time_created,
                    battery: currentData.voltageMv,
                  },
                },
              ],
            }}
          />
        </>
      )}

      <Layer
        id="polylineHistory"
        type="line"
        source="polylineHistory"
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": CONFIG.primaryColour,
          "line-width": 3,
          "line-opacity": 1,
        }}
      />
      <Layer
        id="device-history-circles"
        type="circle"
        source="device-history"
        paint={{
          "circle-radius": 6,
          "circle-color": CONFIG.primaryColour,
          "circle-opacity": 0.8,
        }}
      />
      <Layer
        id="currentLocation"
        type="symbol"
        source="dot-point"
        layout={{
          "icon-image": "pulsing-dot",
        }}
      />
      {
        //add controls for zooming and rotating,
      }

      <NavigationControl position="top-left" />
      <FullscreenControl position="top-left" />
      <ScaleControl position="bottom-right" />
    </Map>
  );
}
