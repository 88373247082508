import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export default function DistanceCard(props) {
  const [lastDistance, setLastDistance] = React.useState("N/A");

  const deviceHistory = useSelector((state) => state.deviceHistory);
  useEffect(() => {
    if (deviceHistory && deviceHistory.length > 0) {
      //get last data object
      let lastDataObject = deviceHistory[deviceHistory.length - 1];
      //parse data
      let jsonData = lastDataObject.data;
      jsonData = JSON.parse(jsonData);
      //get last level
      let lastDistance = jsonData.distance;
      //set last level
      setLastDistance(lastDistance);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardHeader title="Distance" />
      <CardContent>
        <Typography
          variant="h4"
          //dont wrap text
          noWrap
          //hide overflow
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {lastDistance === null
            ? "N/A"
            : lastDistance === 0
            ? "0"
            : lastDistance + " m"}
        </Typography>
      </CardContent>
    </Card>
  );
}
