import React from "react";
import { Cancel } from "@mui/icons-material";
import { Avatar } from "@mui/material";

export default function DeviceTypeAvatar({ device }) {
  return (
    <Avatar
      sx={{
        fontSize: "1rem",
      }}
    >
      {
        //first letter of device_type
        device.device_type === 1 ? (
          "TRK"
        ) : device.device_type === 2 ? (
          "IAQ"
        ) : device.device_type === 3 ? (
          "TH"
        ) : device.device_type === 4 ? (
          "TRK"
        ) : device.device_type === 5 ? (
          "DST"
        ) : device.device_type === 6 ? (
          "MTN"
        ) : device.device_type === 7 ? (
          "TRK"
        ) : device.device_type === 8 ? (
          "CNT"
        ) : device.device_type === 9 ? (
          "TMP"
        ) : device.device_type === 10 ? (
          "MTN"
        ) : device.device_type === 11 ? (
          "DW"
        ) : device.device_type === 12 ? (
          "WL"
        ) : device.device_type === 13 ? (
          "IAQ"
        ) : device.device_type === 14 ? (
          "PET"
        ) : device.device_type === 15 ? (
          "TRK"
        ) : device.device_type === 16 ? (
          "TRK"
        ) : device.device_type === 17 ? (
          "LLP"
        ) : device.device_type === 18 ? (
          "SMRT"
        ) : device.device_type === 19 ? (
          "WF"
        ) : device.device_type === 20 ? (
          "DST"
        ) : device.device_type === 21 ? (
          "PWR"
        ) : device.device_type === 22 ? (
          "WL"
        ) : device.device_type === 23 ? (
          "IAQ"
        ) : (
          <Cancel />
        )
      }
    </Avatar>
  );
}
