import React, { useEffect, useState, useMemo } from "react";
import { Box, Grid, Stack, Tooltip, Typography, Button } from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DataTableBase from "../components/DataTableBase";
import ContactAddDialog from "../components/Dialogs/ContactAdd";
import ContactEditDialog from "../components/Dialogs/ContactEdit";
import ContactDeleteDialog from "../components/Dialogs/ContactDelete";
import { useTranslation } from "react-i18next";
import fetchContacts from "../Functions/fetchContacts";
import { formatDate } from "../Functions/formatDate";
import moment from "moment";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import checkBilling from "../Functions/checkBilling";

function Contacts() {
  const theme = useTheme();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [editContact, setEditContact] = useState({});
  const [addContactDialogOpen, setAddContactDialogOpen] = useState(false);
  const [editContactDialogOpen, setEditContactDialogOpen] = useState(false);
  const [deleteContactDialogOpen, setDeleteContactDialogOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation("common");

  const user = useSelector((state) => state.user);
  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );
  const isMobile = useSelector((state) => state.isMobile);
  const contacts = useSelector((state) => state.contacts);

  if (checkBilling() === false) return;

  //columns for contacts for data table
  const columns = useMemo(
    () => [
      {
        name: "id",
        label: t("common.id"),
        options: {
          filter: true,
          display: false,
        },
      },
      {
        name: "name",
        options: {
          label: t("common.name"),
          customBodyRender: (value, tableMeta, meta) => {
            return (
              <Typography
                onClick={() => {
                  setEditContact(tableMeta.rowData);
                  setEditContactDialogOpen(true);
                }}
                className="cursor-pointer"
                variant="body1"
                color="primary"
              >
                {value}
              </Typography>
            );
          },
        },
      },
      {
        name: "email",
        label: t("contacts.email"),
        options: {
          display: false,
          customBodyRender: (value) => {
            return <Typography>{value}</Typography>;
          },
        },
      },
      {
        name: "phone",
        label: t("contacts.phone"),
        options: {
          display: false,
          customBodyRender: (value) => {
            return <Typography>{value}</Typography>;
          },
        },
      },
      {
        name: "contact",
        label: t("contacts.contact"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, meta) => {
            if (tableMeta.rowData[5] === "email") {
              return <Typography>{tableMeta.rowData[2]}</Typography>;
            } else if (tableMeta.rowData[5] === "phone") {
              return <Typography>{tableMeta.rowData[3]}</Typography>;
            } else if (tableMeta.rowData[5] === "both") {
              return (
                <Typography>
                  {tableMeta.rowData[2]} / {tableMeta.rowData[3]}
                </Typography>
              );
            }
          },
        },
      },
      {
        name: "contact_method",
        label: t("contacts.contact_method"),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, meta) => {
            if (value === "email") {
              return <Typography>{t("contacts.email")}</Typography>;
            } else if (value === "phone") {
              return <Typography>{t("contacts.phone")}</Typography>;
            } else if (value === "both") {
              return <Typography>{t("contacts.email_and_sms")}</Typography>;
            }
          },
        },
      },
      {
        name: "options",
        label: t("common.options"),
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => {
                  setEditContact(tableMeta.rowData);
                  setEditContactDialogOpen(true);
                }}
              >
                {t("common.edit")}
              </Button>
            );
          },
        },
      },
    ],
    []
  );

  const toggleDeleteContactDialogOpen = () => {
    if (selectedContacts.length === 0) {
      updateSnackbarMessage(t("contacts.none_selected_delete"));

      return;
    }
    setDeleteContactDialogOpen(!deleteContactDialogOpen);
  };

  useEffect(() => {
    setTitle(t("contacts.name"), selectedOrganisation);
  }, [selectedOrganisation]);

  const tableActions = [
    {
      icon: "Delete",
      name: t("contacts.delete_contacts"),
      action: () => toggleDeleteContactDialogOpen(),
    },
  ];

  return (
    <>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent={isMobile ? "flex-start" : "flex-end"}
            sx={{ mt: 1 }}
          >
            <PageTitle
              theme={theme}
              title={
                selectedOrganisation &&
                selectedOrganisation &&
                selectedOrganisation.name
                  ? selectedOrganisation.name + " " + t("contacts.name")
                  : t("contacts.name")
              }
            />
            {
              // if user is not the owner of the organisation, don't show the add device button
              selectedOrganisation &&
              user &&
              selectedOrganisation.owner_id === user.id ? (
                <Tooltip title="Add a Contact" placement="top" arrow>
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    onClick={() => {
                      setAddContactDialogOpen(true);
                    }}
                    sx={{ mr: 1 }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              ) : null
            }
            <Tooltip title="Refresh Contacts" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => {
                  fetchContacts(selectedOrganisation.id);
                  updateSnackbarMessage(
                    t("common.refreshed") + " " + formatDate(moment())
                  );
                }}
              >
                <Refresh />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>

      <Box sx={{ mt: 1 }}>
        {
          // list all contacts
        }
        <DataTableBase
          columns={columns}
          data={contacts}
          title={t("contacts.name")}
          id="contact-list"
          name="contact-list"
          pageSize={10}
          selectedItems={selectedContacts}
          setSelectedItems={setSelectedContacts}
          allItems={contacts}
          selectableRows="multiple"
          actions={tableActions}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        <ContactAddDialog
          open={addContactDialogOpen}
          handleClose={() => setAddContactDialogOpen(false)}
        />

        <ContactEditDialog
          open={editContactDialogOpen}
          handleClose={() => setEditContactDialogOpen(false)}
          editContact={editContact}
        />

        <ContactDeleteDialog
          open={deleteContactDialogOpen}
          onClose={async () => {
            setSelectedContacts([]);
            setSelectedRows([]);
            setDeleteContactDialogOpen(false);
          }}
          selectedContacts={selectedContacts}
        />
      </Box>
    </>
  );
}

export default withAuthenticationRequired(Contacts);
