//import deviceHistory, use type prop to get data for each history period and show avg and max
import { useTheme } from "@emotion/react";
import { Card, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

export default function RangeCards(props) {
  const { values, unit } = props;
  const [minimum, setMinimum] = React.useState(0);
  const [maximum, setMaximum] = React.useState(0);
  const [average, setAverage] = React.useState(0);

  const theme = useTheme();

  useEffect(() => {
    //find min max and average

    let val = [];

    //get min max and average from values array, column value
    values.values.forEach((value) => {
      val.push(value[0]);
    });

    if (val.length > 0) {
      //if its not a number, return
      let sum = 0;
      val.forEach((data) => {
        if (isNaN(data)) {
          return;
        }
        sum += data;
      });

      //sort values and get min and max
      val.sort((a, b) => a - b);

      let avg = sum / val.length;
      let max = val[val.length - 1];
      let min = val[0];

      setAverage(avg);
      setMaximum(max);
      setMinimum(min);
    }
  }, [values]);

  // return a card showing min max and average
  return (
    <Stack
      direction="row"
      //full width fill space

      sx={{
        width: "95%",
        px: 1,
        pb: 1,
        justifyContent: "space-between",
      }}
    >
      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#FAFAFA",
          p: 2,
          boxShadow: 0,
          width: "31%",
          mr: 1,
        }}
      >
        <Typography variant="body1">Min</Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="h5">{minimum}</Typography>

          <Typography variant="caption">{unit}</Typography>
        </Stack>
      </Card>

      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#FAFAFA",
          p: 2,
          boxShadow: 0,
          width: "31%",
          mr: 1,
        }}
      >
        <Typography variant="body1">Max</Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="h5">{maximum}</Typography>

          <Typography variant="caption">{unit}</Typography>
        </Stack>
      </Card>

      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1f1f1f" : "#FAFAFA",
          p: 2,
          boxShadow: 0,
          width: "31%",
        }}
      >
        <Typography variant="body1">Avg</Typography>
        <Stack direction="row" alignItems="baseline">
          <Typography variant="h5">
            {
              //show average with 2 decimal places
              average.toFixed(1)
            }
          </Typography>

          <Typography variant="caption">{unit}</Typography>
        </Stack>
      </Card>
    </Stack>
  );
}
