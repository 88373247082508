import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

export default function WaterLeakDetectedCardBig(props) {
  const deviceHistory = useSelector((state) => state.deviceHistory);
  const [leak, setLeak] = React.useState(false);

  React.useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      if (props.field) {
        jsonData = jsonData[props.field];
      } else {
        jsonData.water_leak === 1 ? setLeak(true) : setLeak(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Water Leak Detected?" />
      <CardContent>
        <Tooltip title={leak ? `Leak Detected: Yes` : `Leak Detected: No`}>
          <Typography
            variant="h2"
            color={leak ? "error" : "primary"}
            className="cursor-pointer"
          >
            {leak ? "Yes" : "No"}
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
