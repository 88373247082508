import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import OrganisationMemberDeleteDialog from "../Dialogs/organisationMemberDelete";

export default function OrganisationMemberCard(props) {
  const { member, organisation, refreshMembers } = props;
  const [
    organisationMemberDeleteDialogOpen,
    setOrganisationMemberDeleteDialogOpen,
  ] = React.useState(false);

  return (
    <Grid item xs={12} md={6} lg={3} sx={{ mb: 1 }}>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          {
            // capitalise the first letter of the email
          }
          <Typography gutterBottom variant="h5" component="h2">
            {member.email.charAt(0).toUpperCase() + member.email.slice(1)}
          </Typography>
        </CardContent>
        <CardActions>
          {organisation &&
          organisation.owner_id &&
          member &&
          member.user_id &&
          organisation.owner_id === member.user_id ? (
            <Button variant="outlined" color="primary" disabled>
              Owner
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setOrganisationMemberDeleteDialogOpen(true);
              }}
            >
              Remove Member
            </Button>
          )}
        </CardActions>
      </Card>

      <OrganisationMemberDeleteDialog
        open={organisationMemberDeleteDialogOpen}
        onClose={() => {
          refreshMembers();
          setOrganisationMemberDeleteDialogOpen(false);
        }}
        member_id={member.user_id}
        organisation={organisation}
      />
    </Grid>
  );
}
