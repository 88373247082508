import axios from "axios";
import { CONFIG } from "../config";
import { getHeaders } from "./getHeaders";

export default async function fetchData(url) {
  let headers = await getHeaders();

  try {
    const response = await axios.get(CONFIG.API_URL + url, {
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
