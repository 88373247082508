import * as React from "react";
import Map, {
  FullscreenControl,
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from "react-map-gl";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { CONFIG } from "../../config";
import mapboxgl from "mapbox-gl";
import { IconButton } from "@mui/material";
import { FitScreen } from "@mui/icons-material";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { updatePitch } from "../../Functions/updatePitch";
import { formatDate } from "../../Functions/formatDate";
import convertMvToPercent from "../../Functions/convertMvToPercent";

export default function DevicesMap() {
  const devices = useSelector((state) => state.devices);
  //remove any devices where lat lng is null, 0 or undefined
  const filteredDevices = devices.filter((device) => device.lat && device.lng);

  const mapRef = React.useRef(null);
  const pitch = useSelector((state) => state.pitch);

  const fitToHistory = () => {
    if (filteredDevices.length) {
      const bounds = new mapboxgl.LngLatBounds();
      filteredDevices.forEach((device) => {
        bounds.extend([device.lng, device.lat]);
      });
      mapRef.current.fitBounds(bounds, {
        padding: 50,
        animate: true,
      });
    }
  };

  const handleMapLoad = (e) => {
    mapRef.current = e.target;
    //add image for location history marker
    e.target.loadImage(
      CONFIG.websiteURL + "/assets/images/device-icon.png",
      function (error, image) {
        //if error, try loading it from localhost:3000
        if (error) {
          e.target.loadImage(
            "http://localhost:3000/assets/images/device-icon.png",
            function (error, image) {
              //if error, return
              if (error) return;
              e.target.addImage("marker-15", image);
            }
          );
          return;
        }
        e.target.addImage("marker-15", image);
      }
    );

    if (filteredDevices.length) {
      const bounds = new mapboxgl.LngLatBounds();
      filteredDevices.forEach((device) => {
        bounds.extend([device.lng, device.lat]);
      });
      e.target.fitBounds(bounds, {
        padding: 50,
        animate: false,
      });
    }
    e.target.addControl(pitchToggle, "top-left");
    e.target.addControl(fitToHistoryToggle, "top-left");

    //on devices click, add popup
    e.target.on("click", "devices", (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;

      //center map on selected device
      e.target.flyTo({
        center: coordinates,
        zoom: 15,
      });

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(e.target);
    });
  };

  class FitToHistory {
    onAdd(map) {
      this._map = map;

      const iconButton = document.createElement("div");
      ReactDOM.render(
        <IconButton
          aria-label="fit to history"
          onClick={() => {
            fitToHistory();
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FitScreen sx={{ color: "black" }} />
        </IconButton>,
        iconButton
      );

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(iconButton);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  class PitchToggle {
    constructor({ bearing = 0, pitch = 75, minpitchzoom = null }) {
      this._bearing = bearing;
      this._pitch = pitch;
      this._minpitchzoom = minpitchzoom;
    }

    onAdd(map) {
      this._map = map;
      let _this = this;

      this._btn = document.createElement("button");

      // get pitch and set button icon
      if (localStorage.getItem("pitch") === "0") {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        this._btn.title = "Toggle Pitch (3D)";
      } else {
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        this._btn.title = "Toggle Pitch (2D)";
      }
      this._btn.type = "button";
      this._btn["aria-label"] = "Toggle Pitch";
      this._btn.onclick = function () {
        if (map.getPitch() === 0) {
          let options = { pitch: _this._pitch, bearing: _this._bearing };
          if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
            options.zoom = _this._minpitchzoom;
          }
          updateSnackbarMessage("3D View Enabled");
          updatePitch(75);
          //set pitch in local storage so it can be retrieved when the page is refreshed
          localStorage.setItem("pitch", 75);

          map.easeTo({ pitch: 75, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        } else {
          updatePitch(0);
          updateSnackbarMessage("2D View Enabled");
          localStorage.setItem("pitch", 0);

          map.easeTo({ pitch: 0, bearing: 0 });
          _this._btn.className =
            "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        }
      };

      this._container = document.createElement("div");
      this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
      this._container.appendChild(this._btn);

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const pitchToggle = new PitchToggle({
    bearing: 0,
    pitch: pitch,
    minpitchzoom: null,
  });
  const fitToHistoryToggle = new FitToHistory();

  return (
    <Map
      mapboxApiAccessToken={CONFIG.mapboxAccessToken}
      mapStyle={CONFIG.mapStyle}
      onLoad={(e) => {
        handleMapLoad(e);
      }}
    >
      <NavigationControl position="top-left" />
      <FullscreenControl position="top-left" />
      <ScaleControl position="bottom-right" />

      <Source
        id="devices"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: filteredDevices.map((device) => {
            let data = JSON.parse(device.last_data);

            return {
              type: "Feature",
              properties: {
                description: `<h3>${device.name}</h3><p>Last Seen: ${formatDate(
                  device.time_updated
                )}</p>
                <p>Location: ${
                  data.geocode
                }</p><p>Battery: ${convertMvToPercent(
                  device.batteryMv,
                  device.device_brand_id
                )}%</p>`,
              },
              geometry: {
                type: "Point",
                coordinates: [device.lng, device.lat],
              },
            };
          }),
        }}
      />
      <Layer
        id="devices"
        type="symbol"
        source="devices"
        layout={{
          "icon-image": "marker-15",
          "icon-allow-overlap": true,
        }}
        paint={{
          "icon-color": "#00D09F",
        }}
      />
    </Map>
  );
}
