import React, { useEffect } from "react";
import GaugeComponent from "react-gauge-component";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import AvgAndMax from "../../Cards/avgAndMax";
import { useSelector } from "react-redux";

export default function DistanceGuague(props) {
  const [percentFull, setPercentFull] = React.useState(null);
  const [defaultArch, setDefaultArch] = React.useState([
    {
      limit: 24,
      color: "#EA4228",
    },
    {
      limit: 75,
      color: "#5BE12C",
    },
    {
      color: "#EA4228",
    },
  ]);

  const deviceHistory = useSelector((state) => state.deviceHistory);

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }
    try {
      //get data
      let jsonData = deviceHistory[deviceHistory.length - 1].data;
      //parse json data
      jsonData = JSON.parse(jsonData);

      if (props.deviceProfile) {
        let deviceProfile = JSON.parse(props.deviceProfile);
        //use min as 100% and max as 0%
        let percentFull =
          100 - (jsonData.distance / deviceProfile.distance.max) * 100;

        //to fixed 2
        percentFull = percentFull.toFixed(1);

        setPercentFull(percentFull);

        setDefaultArch([
          {
            limit: parseFloat(deviceProfile.distance.safe_range_start - 1),
            color: "#EA4228",
          },
          {
            limit: parseFloat(deviceProfile.distance.safe_range_end),
            color: "#5BE12C",
          },
          {
            color: "#EA4228",
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [deviceHistory, props.deviceProfile]);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title="Fluid Level"
        action={
          <Typography variant="h5">
            {" "}
            {percentFull ? percentFull + "%" : "N/A"}
          </Typography>
        }
      />
      <GaugeComponent
        type="semicircle"
        arc={{
          width: 0.2,
          padding: 0.005,
          cornerRadius: 1,
          //gradient: true,
          subArcs: defaultArch,
        }}
        pointer={{
          color: "#345243",
          length: 0.8,
          width: 15,
          // elastic: true,
        }}
        labels={{
          valueLabel: { formatTextValue: (value) => "" },
        }}
        value={percentFull ? percentFull : 0}
        minValue={0}
        maxValue={100}
      />
      <Box sx={{ mt: "auto" }}>
        <AvgAndMax
          deviceHistory={deviceHistory}
          type="percentFull"
          unit="°C"
          deviceProfile={props.deviceProfile}
        />
      </Box>
    </Card>
  );
}
