//dialogbase to add organisation
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { putData } from "../../API/Put";
import OrganisationDeleteDialog from "./organisationDelete";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function OrganisationSettingsDialog(props) {
  const { open, handleClose, organisation } = props;
  const { t } = useTranslation("common");

  const [deleteOrganisationDialogOpen, setDeleteOrganisationDialogOpen] =
    React.useState(false);
  const [organisationId, setOrganisationId] = React.useState(null);

  const { handleSubmit, control, setValue } = useForm({
    reValidateMode: "onBlur",
  });

  const handleDeleteOrganisationDialogClose = () => {
    setDeleteOrganisationDialogOpen(false);
  };

  useEffect(() => {
    if (organisation !== null) {
      setValue("name", organisation.name);
      setValue("description", organisation.description);
      setOrganisationId(organisation.id);
    }
  }, [organisation]);

  const onSubmit = async (data) => {
    const { name, description } = data;

    const organisation = {
      name: name,
      description: description,
    };

    let response = await putData(
      `/organisation/${organisationId}`,
      organisation
    );

    if (response === undefined) {
      updateSnackbarMessage("Error updating organisation");
      handleClose();
      return;
    }

    if (response.status !== 200) {
      updateSnackbarMessage("Error updating organisation");
      handleClose();
      return;
    }

    if (response.status === 200) {
      updateSnackbarMessage("Organisation updated successfully");
      handleClose();
      return;
    }
  };

  const validationLabels = {
    name: {
      required: t("organisations.name_required"),
    },
    description: {
      required: t("organisations.description_required"),
    },
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="organisation-add-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="organisation-add-dialog-title">
          {t("organisations.settings")}
        </DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                id="name"
                label={t("organisations.org_name")}
                type="text"
                fullWidth
                {...field}
                error={error !== undefined}
                helperText={error ? validationLabels.name[error.type] : ""}
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                margin="dense"
                id="description"
                label={t("common.description")}
                multiline
                rows={4}
                fullWidth
                {...field}
                error={error !== undefined}
                helperText={
                  error ? validationLabels.description[error.type] : ""
                }
                variant="outlined"
                sx={{
                  mt: 1,
                }}
              />
            )}
          />

          <Button
            variant="contained"
            color="error"
            sx={{
              mt: 1,
            }}
            onClick={() => {
              setDeleteOrganisationDialogOpen(true);
            }}
          >
            {t("organisations.delete")}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>

      <OrganisationDeleteDialog
        open={deleteOrganisationDialogOpen}
        handleClose={handleDeleteOrganisationDialogClose}
        handleDelete={
          //firstly close that dialog, and then close this one
          () => {
            setDeleteOrganisationDialogOpen(false);

            handleClose();
          }
        }
        organisation={organisation}
      />
    </>
  );
}
