import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { CONFIG } from "../config";
import createGeoJSONCircle from "../Functions/createGeoJSONCircle";
import addImages from "../Functions/mapAddImages";
import { useNavigate, useParams } from "react-router-dom";
import fetchData from "../API/Fetch";
import { putData } from "../API/Put";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import checkBilling from "../Functions/checkBilling";
import { useTranslation } from "react-i18next";

mapboxgl.accessToken = CONFIG.mapboxAccessToken;

export default function GeofenceEdit(props) {
  const map = useRef(null);
  //store the location from the map when clicked
  const [location, setLocation] = useState();
  const [radius, setRadius] = useState(100);
  const [name, setName] = useState("");
  const [outOfHours, setOutOfHours] = useState(false);
  const [startTime, setStartTime] = useState("17:30");
  const [endTime, setEndTime] = useState("09:00");

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [alertType, setAlertType] = useState("enter-exit");

  const contacts = useSelector((state) => state.contacts);

  const { t } = useTranslation("common");

  const theme = useTheme();

  const params = useParams();
  const navigate = useNavigate();

  async function updateGeofence() {
    //if location is not set, alert the user
    if (!location) {
      alert("Please select a location");
      return;
    }
    //if name is not set, alert the user
    if (!name) {
      alert("Please enter a name");
      return;
    }

    try {
      let response = await putData(`/geofence/${params.id}`, {
        name: name,
        radius: radius,
        longitude: location.lng,
        latitude: location.lat,
        out_of_hours: outOfHours,
        start_time: startTime,
        end_time: endTime,
        contacts: selectedContacts,
        alert_type: alertType,
        organisation_id: localStorage.getItem("selectedOrganisationID"),
      });

      if (response.status === 200) {
        updateSnackbarMessage("Geofence Updated");
        navigate("/geofence");
      } else {
        updateSnackbarMessage("Error Updating geofence");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    checkBilling();
    fetchData(`/geofence/${params.id}`).then((geofence) => {
      if (geofence && geofence.length === 0) {
        navigate("/geofence");
      }

      setTitle(`Edit Geofence ${geofence.name}`);

      setName(geofence.name);
      setRadius(geofence.radius);
      setLocation({
        lng: geofence.longitude,
        lat: geofence.latitude,
      });
      setOutOfHours(geofence.out_of_hours);
      setStartTime(geofence.start_time);
      setEndTime(geofence.end_time);
      setAlertType(geofence.alert_type);

      //get geofence contacts
      fetchData(`/geofence/${params.id}/contacts`).then((result) => {
        setSelectedContacts(result);
      });

      document.title = `Edit Geofence ${
        geofence && geofence.name ? geofence.name : ""
      } | ${CONFIG.siteTitle} - ${CONFIG.siteTitleDescription}`;

      map.current = new mapboxgl.Map({
        container: "map",
        style: CONFIG.mapStyle,
        center: [geofence.longitude, geofence.latitude],
        zoom: 15,
      });
      addImages(map.current);

      //add a source layer for the marker circle
      map.current.on("load", function () {
        //check if sources exist, and return if they do
        if (map.current.getSource("point")) return;
        if (map.current.getSource("radius")) return;
        map.current.addSource("point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [geofence.longitude, geofence.latitude],
                },
              },
            ],
          },
        });

        //add a layer showing a marker
        map.current.addLayer({
          id: "point",
          source: "point",
          type: "symbol",
          layout: {
            "icon-image": "marker-15",
            "icon-size": 1,
          },
        });

        const circle = createGeoJSONCircle(
          [geofence.longitude, geofence.latitude],
          geofence.radius / 1000,
          100
        );
        //add a source for the createGeoJSONCircle polygon
        map.current.addSource("radius", {
          type: "geojson",
          data: circle,
        });
        //add a layer for the createGeoJSONCircle polygon
        map.current.addLayer({
          id: "radius",
          source: "radius",
          type: "fill",
          paint: {
            "fill-color": theme.palette.primary.main,
            "fill-opacity": 0.5,
          },
        });

        map.current.addControl(new mapboxgl.NavigationControl(), "top-left");
        //add locate control
        map.current.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
          }),
          "top-left"
        );

        // add fullscreen control top left
        map.current.addControl(new mapboxgl.FullscreenControl(), "top-left");
      });

      // on click create a geoJSON circle and update pointer source and radius source
      map.current.on("click", (e) => {
        setLocation({
          lng: e.lngLat.lng,
          lat: e.lngLat.lat,
        });
        setRadius(100);

        //set the location of the point layer to where the user clicked
        map.current.getSource("point").setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [e.lngLat.lng, e.lngLat.lat],
              },
            },
          ],
        });
        //create a geoJSON circle
        const circle = createGeoJSONCircle(
          [e.lngLat.lng, e.lngLat.lat],
          radius / 1000,
          100
        );
        //set the radius source to the geoJSON circle
        map.current.getSource("radius").setData(circle);
      });
    });
  }, [params.id]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} sx={{ mb: 1 }}>
        <PageTitle
          theme={theme}
          title={t("geofences.edit_title", { name: name })}
        />
        <Typography variant="body1" color="white" sx={{ mb: 1 }}>
          {t("geofences.edit_intro")}
        </Typography>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box sx={{ height: "100%" }}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Geofence Details
              </Typography>
              {
                // set geofence name
              }
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ mb: 1 }}
                />
              </FormControl>

              {
                // set geofence radius
              }

              <Grid
                container
                spacing={2}
                sx={{
                  my: 1,
                  //align items vertically center
                  alignItems: "center",
                }}
              >
                <Grid item xs={6} lg={3}>
                  <TextField
                    id="outlined-basic"
                    label="Radius"
                    variant="outlined"
                    fullWidth
                    value={radius}
                    onChange={(e) => {
                      //if the less than 100, set to 100
                      if (e.target.value < 100) {
                        setRadius(100);
                        if (location && location.lat) {
                          //set the radius source to the geoJSON circle
                          const circle = createGeoJSONCircle(
                            [location.lng, location.lat],
                            e.target.value / 1000,
                            100
                          );
                          map.current.getSource("radius").setData(circle);
                        }
                        return;
                      }
                      setRadius(e.target.value);
                      if (location && location.lat) {
                        //set the radius source to the geoJSON circle
                        const circle = createGeoJSONCircle(
                          [location.lng, location.lat],
                          e.target.value / 1000,
                          100
                        );
                        map.current.getSource("radius").setData(circle);
                      }
                    }}
                    min={100}
                    //number input
                    type="number"
                    //add m to the end of the value
                    InputProps={{
                      endAdornment: "m",
                    }}
                  />
                </Grid>
                <Grid item xs={6} lg={9}>
                  <FormControl fullWidth>
                    <Slider
                      aria-label="Radius"
                      defaultValue={radius}
                      step={20}
                      marks={Array.from(Array(1000).keys()).map((i) => ({
                        value: i * 50,
                      }))}
                      min={100}
                      max={50000}
                      onChange={(e, value) => {
                        setRadius(value);
                        if (location && location.lat) {
                          const circle = createGeoJSONCircle(
                            [location.lng, location.lat],
                            value / 1000,
                            100
                          );
                          map.current.getSource("radius").setData(circle);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {
                // alerts
              }
              <Typography variant="h6" sx={{ mb: 1 }}>
                Alerts
              </Typography>
              {
                //entering/exiting/all/none
              }
              <FormControl component="fieldset" sx={{ mb: 1 }} fullWidth>
                <RadioGroup
                  row
                  aria-label="alertType"
                  name="row-radio-buttons-group"
                  value={alertType}
                  onChange={(e) => setAlertType(e.target.value)}
                >
                  <FormControlLabel
                    value="enter-exit"
                    control={<Radio />}
                    label="Enter/Exit"
                  />
                  <FormControlLabel
                    value="enter"
                    control={<Radio />}
                    label="Enter"
                  />
                  <FormControlLabel
                    value="exit"
                    control={<Radio />}
                    label="Exit"
                  />

                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="None"
                  />
                </RadioGroup>
              </FormControl>

              {alertType !== "none" && (
                <>
                  {
                    //out of hours checkbox
                  }
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={outOfHours ? true : false}
                          onChange={(e) => setOutOfHours(e.target.checked)}
                          name="outOfHours"
                          color="primary"
                        />
                      }
                      sx={{ mb: 1 }}
                      label="Out of Hours"
                    />
                  </FormControl>

                  {
                    //if out of hours is true, show start and end time
                  }
                  {outOfHours ? (
                    <>
                      <TextField
                        id="time"
                        label="Start Time"
                        type="time"
                        defaultValue="17:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        fullWidth
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        sx={{ mb: 1 }}
                      />
                      <TextField
                        id="time"
                        label="End Time"
                        type="time"
                        defaultValue="07:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        fullWidth
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        sx={{ mb: 1, mt: 1 }}
                      />
                    </>
                  ) : null}

                  {
                    // autocomplete multi select for contacts
                  }
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Recipients
                  </Typography>
                  <Autocomplete
                    multiple
                    id="contacts"
                    options={contacts ? contacts : []}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => setSelectedContacts(value)}
                    renderTags={(value) =>
                      value.map((value, index) => (
                        <Chip
                          variant="outlined"
                          key={index}
                          label={value.name}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Contacts"
                        placeholder="Contacts"
                        sx={{ mb: 1 }}
                        fullWidth
                        rows={4}
                      />
                    )}
                  />
                </>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={updateGeofence}
              >
                Update Geofence
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  //redirect to geofences page
                  navigate("/geofence");
                }}
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Grid>

      <Grid item xs={12} md={8}>
        <div id="map" style={{ height: "700px" }}></div>
      </Grid>
    </Grid>
  );
}
