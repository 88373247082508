import React from "react";
import { Route, Routes } from "react-router-dom";
import Devices from "./containers/Devices";
import Account from "./containers/Account";
import Device from "./containers/Device";
import Contacts from "./containers/Contacts";
import Geofences from "./containers/Geofences";
import GeofenceAdd from "./containers/GeofenceAdd";
import GeofenceEdit from "./containers/GeofenceEdit";
import Share from "./containers/Share";
import AuthError from "./containers/AuthError";
import Actions from "./containers/Actions";
import Organisations from "./containers/Organisations";
import OrganisationMembers from "./containers/OrganisationMembers";
import LimitReached from "./containers/LimitReached";
import RegisterCard from "./containers/RegisterCard";
import PaymentSuccess from "./containers/PaymentSuccess";
import SubscriptionSuccess from "./containers/SubscriptionSuccess";
import NotFound from "./containers/NotFound";
import ActionFlow from "./containers/ActionFlow";
import ActionLogs from "./containers/ActionLogs";

export default function AppRoutes(props) {
  return (
    <Routes>
      <Route path="/" exact element={<Devices />} />
      <Route
        path="/account"
        exact
        element={<Account handleThemeChange={props.handleThemeChange} />}
      />
      <Route
        path={"/device"}
        element={<Devices />}
        collapsed={props.collapsed}
      />
      <Route
        path={"/device/:id"}
        element={<Device collapsed={props.collapsed} />}
      />
      <Route path="/contact" element={<Contacts />} />
      <Route path="/geofence" element={<Geofences />} />
      <Route path="/geofence/add" element={<GeofenceAdd />} />
      <Route path="/geofence/:id" element={<GeofenceEdit />} />
      <Route path="/share/:id" element={<Share />} />
      <Route path="/auth-error" element={<AuthError />} />
      <Route path="/action" element={<Actions />} />
      <Route path="/action/:id/logs" element={<ActionLogs />} />
      <Route path="/organisation" element={<Organisations />} />
      <Route
        path="/organisation/:id/members"
        element={<OrganisationMembers />}
      />
      <Route path="/billing/limit-reached" element={<LimitReached />} />
      <Route path="/billing/register-card" element={<RegisterCard />} />
      <Route path="/billing/success" element={<PaymentSuccess />} />
      <Route
        path="/billing/subscription-success"
        element={<SubscriptionSuccess />}
      />
      <Route path="actionflow" element={<ActionFlow />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
