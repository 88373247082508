import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Button, Stack, Grid, useTheme, Tooltip, Box } from "@mui/material";
import { Add, Refresh } from "@mui/icons-material";

//functions
import { useTranslation } from "react-i18next";
import moment from "moment";
import AddDeviceDialog from "../components/Dialogs/addDevice";
import DeviceCard from "../components/Cards/Device";
import { formatDate } from "../Functions/formatDate";
import AddDeviceCard from "../components/Cards/AddDevice";
import { useSelector } from "react-redux";
import { fetchDevices } from "../Functions/fetchDevices";
import updateSnackbarMessage from "../Functions/updateSnackbarMessage";
import isTracker from "../Functions/isTracker";
import PageTitle from "../components/PageTitle";
import setTitle from "../Functions/setTitle";
import checkBilling from "../Functions/checkBilling";
import DevicesMap from "../components/Maps/Devices";

function Devices(props) {
  const theme = useTheme();

  const selectedOrganisation = useSelector(
    (state) => state.selectedOrganisation
  );

  const devices = useSelector((state) => state.devices);
  const user = useSelector((state) => state.user);

  const [addDeviceOpen, setAddDeviceOpen] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [noDevices, setNoDevices] = useState(false);

  if (checkBilling() === false) return;

  const { t } = useTranslation("common");

  useEffect(() => {
    //if any device is a tracker isTracker, show the map
    if (devices && devices.length) {
      setShowMap(devices.some((device) => isTracker(device.device_type)));
      setNoDevices(false);
    } else {
      setNoDevices(true);
      setShowMap(false);
    }
    if (selectedOrganisation) {
      if (selectedOrganisation && selectedOrganisation.name !== undefined) {
        setTitle(t("devices.title"), selectedOrganisation);
      }
    }
  }, [devices, selectedOrganisation]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              mt: 1,
              //verticalAlign: "middle",
              verticalAlign: "middle",
            }}
          >
            <PageTitle
              title={
                selectedOrganisation &&
                selectedOrganisation &&
                selectedOrganisation.name
                  ? selectedOrganisation.name + " " + t("devices.title")
                  : t("devices.title")
              }
              theme={theme}
            />
            {
              // if user is not the owner of the organisation, don't show the add device button
              selectedOrganisation &&
              user &&
              selectedOrganisation.owner_id === user.id ? (
                <Tooltip title="Add a Device" placement="top" arrow>
                  <Button
                    variant="outlined"
                    color={
                      theme.palette.mode === "dark" ? "primary" : "secondary"
                    }
                    sx={{ mr: 1 }}
                    onClick={() => {
                      setAddDeviceOpen(true);
                    }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              ) : null
            }
            <Tooltip title="Refresh Devices" placement="top" arrow>
              <Button
                variant="outlined"
                color={theme.palette.mode === "dark" ? "primary" : "secondary"}
                onClick={() => {
                  fetchDevices(localStorage.getItem("selectedOrganisationID"));
                  updateSnackbarMessage(
                    t("common.refreshed") + " " + formatDate(moment())
                  );
                }}
              >
                <Refresh />
              </Button>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={1}>
          {showMap ? (
            <Grid item xs={12} sx={{ mb: 1 }}>
              <DevicesMap />
            </Grid>
          ) : null}
          {devices && devices.length
            ? devices.map((device) => {
                return <DeviceCard device={device} key={device.id} />;
              })
            : null}
          {noDevices ? (
            <AddDeviceCard setAddDeviceOpen={setAddDeviceOpen} />
          ) : null}
        </Grid>
      </Box>
      <AddDeviceDialog
        open={addDeviceOpen}
        handleClose={() => {
          setAddDeviceOpen(false);
          fetchDevices(localStorage.getItem("selectedOrganisationID"));
        }}
      />
    </>
  );
}

export default withAuthenticationRequired(Devices);
