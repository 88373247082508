import React, { useEffect } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Stack,
  MenuItem,
  InputAdornment,
  Typography,
  Chip,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { putData } from "../../API/Put";
import { useSelector } from "react-redux";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function ActionEditDialog(props) {
  const { open, handleClose, selectedActionID } = props;
  const { t } = useTranslation("common");

  const [thresholdAdornment, setThresholdAdornment] = React.useState("%");
  const [selectedDevices, setSelectedDevices] = React.useState([]);
  const [selectedContacts, setSelectedContacts] = React.useState([]);

  const contacts = useSelector((state) => state.contacts);
  const devices = useSelector((state) => state.devices);
  const selectedOrganisationID = localStorage.getItem("selectedOrganisationID");
  const actions = useSelector((state) => state.actions);

  //fetch action
  const fetchAction = async () => {
    if (!selectedActionID || !open) return;

    const action = actions.find((action) => action.id === selectedActionID);

    console.log("action", action);

    if (!action) return;

    unregisterAllFields();
    if (action.action_type === "forward") {
      registerForwardFields();
    } else {
      registerAlertFields();
    }

    const {
      name,
      action_type,
      alert_type,
      message,
      above_below,
      threshold,
      forwarder_url,
      forwarder_headers,
      out_of_hours,
      start_time,
      end_time,
      openclose,
      openclosetime,
      true_false,
    } = action;

    setValue("name", name);
    setValue("actionType", action_type);
    setValue("alertType", alert_type);
    setValue("message", message);
    setValue("aboveBelow", above_below);

    let thresh = threshold;

    //check if  temperature unit is F, convert to C
    let temperature_unit = localStorage.getItem("temperature_unit") || "C";
    if (temperature_unit === "F") {
      thresh = ((thresh - 32) * 5) / 9;
    }

    setValue("threshold", thresh);

    setValue("forwarderUrl", forwarder_url);
    setValue("forwarderHeaders", forwarder_headers);
    setValue("outOfHours", out_of_hours ? "true" : "false");
    setValue("startTime", start_time);
    setValue("endTime", end_time);
    setValue("openclose", openclose ? "true" : "false");
    setValue("openclosetime", openclosetime);
    setValue("true_false", true_false);

    //uswe the id from action.devices and action.contacts to get the objects from devices and contacts for the autocomplete
    let selectedDevices = [];
    let selectedContacts = [];

    action.devices.forEach((deviceID) => {
      let deviceFound = devices.find(
        (device) => device.id === deviceID.device_id
      );
      if (deviceFound) {
        selectedDevices.push(deviceFound);
      }
    });

    action.contacts.forEach((contactID) => {
      let contactFound = contacts.find(
        (contact) => contact.id === contactID.contact_id
      );
      if (contactFound) {
        selectedContacts.push(contactFound);
      }
    });

    setSelectedContacts(selectedContacts);
    setSelectedDevices(selectedDevices);

    //if alert type is temp, humidity, battery, distance, set threshold adornment
    if (action.alert_type === "temperature") {
      setThresholdAdornment("°" + localStorage.getItem("temperature_unit"));
    } else if (
      action.alert_type === "humidity" ||
      action.alert_type === "battery" ||
      action.alert_type === "light"
    ) {
      setThresholdAdornment("%");
    } else if (action.alert_type === "distance") {
      setThresholdAdornment("mm");
    } else {
      setThresholdAdornment("");
    }
  };

  //function to register alert fields
  const registerAlertFields = () => {
    unregisterAllFields();
    register("alertType", {
      required: true,
    });
    register("message", {
      required: true,
    });
    register("name", {
      required: true,
    });
    register("openclose");
    register("true_false");

    return;
  };

  //function to register forward fields
  const registerForwardFields = () => {
    unregisterAllFields();
    register("forwarderUrl", {
      required: true,
    });
    register("forwarderHeaders");
    unregister("message");
    register("name", {
      required: true,
    });

    return;
  };

  const { register, handleSubmit, unregister, watch, control, setValue } =
    useForm({
      reValidateMode: "onBlur",
    });

  const watchActionType = watch("actionType");
  const watchAlertType = watch("alertType");
  const watchOutOfHours = watch("outOfHours");
  const watchopenClose = watch("openclose");
  const watchopenclosetime = watch("openclosetime");

  const validationLabels = {
    name: {
      required: t("actions.name_required"),
    },

    actionType: {
      required: t("actions.action_type_required"),
    },
    alert_type: {
      required: t("actions.alert_type_required"),
    },
    message: {
      required: t("actions.message_required"),
    },
    aboveBelow: {
      required: t("actions.above_below_required"),
    },
    threshold: {
      required: t("actions.threshold_required"),
    },
    forwarderUrl: {
      required: t("actions.forwarder_url_required"),
    },
    forwarderHeaders: {
      required: t("actions.forwarder_headers_required"),
    },
    outOfHours: {
      required: t("actions.out_of_hours_required"),
    },
    startTime: {
      required: t("actions.start_time_required"),
    },
    endTime: {
      required: t("actions.end_time_required"),
    },
    devices: {
      required: t("actions.devices_required"),
    },
    contacts: {
      required: t("actions.contacts_required"),
    },
    openclose: {
      required: t("actions.openclose_required"),
    },
    openclosetime: {
      required: t("actions.openclosetime_required"),
    },
  };

  //function to unregister all fields
  const unregisterAllFields = () => {
    unregister("alertType");
    unregister("message");
    unregister("aboveBelow");
    unregister("threshold");
    unregister("forwarderUrl");
    unregister("forwarderHeaders");
    unregister("outOfHours");
    unregister("startTime");
    unregister("endTime");
    unregister("contacts");
    unregister("openclose");
    unregister("openclosetime");
    unregister("true_false");
  };

  useEffect(() => {
    if (!open) return;
    let mounted = true;
    if (mounted) {
      fetchAction();

      return () => {
        mounted = false;
      };
    }
  }, [selectedActionID, open]);

  useEffect(() => {
    if (watchOutOfHours === "true") {
      register("startTime", {
        required: true,
      });

      register("endTime", {
        required: true,
      });
    } else {
      unregister("startTime");
      unregister("endTime");
    }
  }, [watchOutOfHours]);

  //watch openclose and register openclosetime if true
  useEffect(() => {
    if (watchopenClose === "true") {
      register("openclosetime", {
        required: true,
      });

      //if value for openclosetime is empty, set it to 60
      if (watchopenClose === "true" && watchopenclosetime === "") {
        setValue("openclosetime", 60);
      }
    } else {
      unregister("openclosetime");
    }
  }, [watchopenClose]);

  const onSubmit = async (data) => {
    let {
      name,
      actionType,
      alertType,
      message,
      forwarderHeaders,
      forwarderUrl,
      threshold,
      aboveBelow,
      outOfHours,
      startTime,
      endTime,
      openclose,
      openclosetime,
      true_false,
    } = data;

    let thresh = threshold;

    //check if temperature alertType and if temperature unit is F, convert to C
    if (alertType === "temperature") {
      let temperature_unit = localStorage.getItem("temperature_unit");
      if (temperature_unit === "F") {
        thresh = ((thresh - 32) * 5) / 9;
      }
    }

    //if openclose is 1, and openclosetime is not set, set it to 60
    if (openclose === "true" && openclosetime === "") {
      openclosetime = 60;
    }

    //get the id from the selected devices into a new array
    let selectedDevicesArray = selectedDevices.map((device) => {
      return device.id;
    });

    //get the id from the selected contacts into a new array
    let selectedContactsArray = selectedContacts.map((contact) => {
      return contact.id;
    });

    let jsonData = {
      name: name,
      organisation_id: selectedOrganisationID,
      action_type: actionType,
      alert_type: alertType,
      message: message,
      above_below: aboveBelow,
      threshold: thresh,
      forwarder_url: forwarderUrl,
      forwarder_headers: forwarderHeaders,
      out_of_hours: outOfHours === "true" ? 1 : 0,
      start_time: startTime,
      end_time: endTime,
      devices: selectedDevicesArray,
      contacts: selectedContactsArray,
      temp_unit: localStorage.getItem("temperature_unit") || "C",
      openclose: openclose === "true" ? 1 : 0,
      openclosetime: openclosetime,
      true_false: true_false,
    };

    try {
      let response = await putData(
        `/action/${selectedOrganisationID}/${selectedActionID}`,
        jsonData
      );

      if (response.status === 200) {
        updateSnackbarMessage(t("actions.action_updated"));
      } else {
        updateSnackbarMessage(t("actions.action_update_error"));
      }
    } catch (error) {
      updateSnackbarMessage(t("actions.action_update_error"));
    }

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t("actions.edit_action_title")}</DialogTitle>
        <DialogContent>
          <Stack spacing={1}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <TextField
                    id="name"
                    label={t("common.name")}
                    fullWidth
                    {...field}
                    error={error !== undefined}
                    helperText={error ? validationLabels.name[error.type] : ""}
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                    {...register("name")}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="actionType"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <TextField
                    id="actionType"
                    label={t("actions.action_type")}
                    select
                    fullWidth
                    {...field}
                    error={error !== undefined}
                    helperText={
                      error ? validationLabels.actionType[error.type] : ""
                    }
                    variant="outlined"
                    sx={{
                      mt: 1,
                    }}
                    {...register("actionType")}
                  >
                    <MenuItem value="alert">
                      {t("actions.action_alert")}
                    </MenuItem>
                    <MenuItem value="forward">
                      {t("actions.action_forward")}
                    </MenuItem>
                  </TextField>
                </FormControl>
              )}
            />

            {watchActionType === "alert" && (
              <>
                {
                  //alert type
                }
                <Controller
                  name="alertType"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="alertType"
                        label={t("actions.alert_type")}
                        select
                        fullWidth
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.alert_type[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("alertType")}
                      >
                        <MenuItem value="battery">
                          {t("common.battery")}
                        </MenuItem>

                        <MenuItem value="c02_ppm">
                          {t("common.c02_ppm")}
                        </MenuItem>
                        <MenuItem value="distance">
                          {t("common.distance")}
                        </MenuItem>
                        <MenuItem value="door">{t("common.door")}</MenuItem>
                        <MenuItem value="humidity">
                          {t("common.humidity")}
                        </MenuItem>
                        <MenuItem value="light">{t("common.light")}</MenuItem>
                        <MenuItem value="motion">{t("common.motion")}</MenuItem>
                        <MenuItem value="button_pressed">
                          {t("common.button_pressed")}
                        </MenuItem>
                        <MenuItem value="sos_event">
                          {t("common.sos_button_pressed")}
                        </MenuItem>
                        <MenuItem value="temperature">
                          {t("common.temperature")}
                        </MenuItem>
                        <MenuItem value="water-leak">
                          {t("common.water_leak")}
                        </MenuItem>
                        <MenuItem value="HasFlowRate">
                          {t("common.has_flow_rate")}
                        </MenuItem>
                      </TextField>
                    </FormControl>
                  )}
                />
              </>
            )}
            {watchActionType === "alert" &&
              (watchAlertType === "temperature" ||
                watchAlertType === "humidity" ||
                watchAlertType === "c02_ppm" ||
                watchAlertType === "distance" ||
                watchAlertType === "light" ||
                watchAlertType === "battery") && (
                <>
                  {
                    //above below
                  }
                  <Controller
                    name="aboveBelow"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl>
                        <TextField
                          id="aboveBelow"
                          label={t("actions.above_below")}
                          select
                          fullWidth
                          {...field}
                          error={error !== undefined}
                          helperText={
                            error ? validationLabels.aboveBelow[error.type] : ""
                          }
                          variant="outlined"
                          sx={{
                            mt: 1,
                          }}
                          {...register("aboveBelow")}
                        >
                          <MenuItem value="above">
                            {t("actions.above")}
                          </MenuItem>
                          <MenuItem value="below">
                            {t("actions.below")}
                          </MenuItem>
                        </TextField>
                      </FormControl>
                    )}
                  />

                  {
                    //threshold
                  }
                  <Controller
                    name="threshold"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl>
                        <TextField
                          id="threshold"
                          label={t("actions.threshold")}
                          fullWidth
                          {...field}
                          error={error !== undefined}
                          helperText={
                            error ? validationLabels.threshold[error.type] : ""
                          }
                          variant="outlined"
                          sx={{
                            mt: 1,
                          }}
                          {...register("threshold")}
                          //append degree sybol C
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography variant="body1">
                                  {thresholdAdornment}
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    )}
                  />
                </>
              )}

            {watchAlertType === "HasFlowRate" && (
              <>
                <Controller
                  name="true_false"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="true_false"
                        label={t("actions.true_false")}
                        fullWidth
                        select
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.true_false[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("true_false")}
                      >
                        <MenuItem value="true">{t("common.yes")}</MenuItem>
                        <MenuItem value="false">{t("common.no")}</MenuItem>
                      </TextField>
                    </FormControl>
                  )}
                />
              </>
            )}

            {watchAlertType === "motion" && (
              <>
                {
                  // out of hours?
                }
                <Controller
                  name="outOfHours"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="outOfHours"
                        label={t("actions.out_of_hours")}
                        select
                        fullWidth
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.outOfHours[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("outOfHours")}
                      >
                        <MenuItem value="1">{t("common.yes")}</MenuItem>
                        <MenuItem value="0">{t("common.no")}</MenuItem>
                      </TextField>
                    </FormControl>
                  )}
                />
                {
                  //start time
                }
                {
                  //check out of hours is true
                  watchOutOfHours === "true" && (
                    <>
                      <Controller
                        name="startTime"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl>
                            <TextField
                              id="startTime"
                              label={t("actions.start_time")}
                              fullWidth
                              type="time"
                              {...field}
                              error={error !== undefined}
                              helperText={
                                error
                                  ? validationLabels.start_time[error.type]
                                  : ""
                              }
                              variant="outlined"
                              sx={{
                                mt: 1,
                              }}
                              {...register("startTime")}
                            />
                          </FormControl>
                        )}
                      />
                      {
                        //end time
                      }
                      <Controller
                        name="endTime"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl>
                            <TextField
                              id="endTime"
                              label={t("actions.end_time")}
                              fullWidth
                              type="time"
                              {...field}
                              error={error !== undefined}
                              helperText={
                                error
                                  ? validationLabels.end_time[error.type]
                                  : ""
                              }
                              variant="outlined"
                              sx={{
                                mt: 1,
                              }}
                              {...register("endTime")}
                            />
                          </FormControl>
                        )}
                      />
                    </>
                  )
                }
              </>
            )}

            {watchActionType === "alert" && (
              <>
                <Controller
                  name="openclose"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="openclose"
                        label={t("actions.openclose")}
                        fullWidth
                        select
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.openclose[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("openclose")}
                      >
                        <MenuItem value="true">{t("common.yes")}</MenuItem>
                        <MenuItem value="false">{t("common.no")}</MenuItem>
                      </TextField>
                    </FormControl>
                  )}
                />

                {watchopenClose === "true" && (
                  <Controller
                    name="openclosetime"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl>
                        <TextField
                          id="openclosetime"
                          label={t("actions.openclosetime")}
                          fullWidth
                          {...field}
                          error={error !== undefined}
                          helperText={
                            error
                              ? validationLabels.openclosetime[error.type]
                              : ""
                          }
                          variant="outlined"
                          sx={{
                            mt: 1,
                          }}
                          {...register("openclosetime")}
                        />
                      </FormControl>
                    )}
                  />
                )}
                <Controller
                  name="message"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="message"
                        label={t("actions.message")}
                        fullWidth
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.message[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("message")}
                      />
                    </FormControl>
                  )}
                />

                {
                  //auto complete for contacts
                }
                <Controller
                  control={control}
                  name="contacts"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <Autocomplete
                        multiple
                        id="contacts"
                        options={contacts}
                        value={selectedContacts}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          setSelectedContacts(value);
                        }}
                        renderTags={(value) =>
                          value.map((value, index) => (
                            <Chip
                              variant="outlined"
                              key={index}
                              label={value.name}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("actions.recipients")}
                            id="contacts"
                            fullWidth
                            rows={4}
                            {...field}
                            error={error !== undefined}
                            helperText={
                              error ? validationLabels.contacts[error.type] : ""
                            }
                            variant="outlined"
                            sx={{
                              mt: 1,
                            }}
                            {...register("contacts")}
                          />
                        )}
                      />
                    </FormControl>
                  )}
                />
              </>
            )}

            {watchActionType === "forward" && (
              <>
                {
                  //forwarder url
                }
                <Controller
                  name="forwarderUrl"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="forwarderUrl"
                        label={t("actions.forwarder_url")}
                        fullWidth
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.forwarderUrl[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("forwarderUrl")}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="forwarderHeaders"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl>
                      <TextField
                        id="forwarderHeaders"
                        label={t("actions.forwarder_headers")}
                        fullWidth
                        multiline
                        rows={4}
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error
                            ? validationLabels.forwarderHeaders[error.type]
                            : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("forwarderHeaders")}
                      />
                    </FormControl>
                  )}
                />
              </>
            )}
            {
              //select multiple devices using autocomplete
            }
            <Controller
              control={control}
              name="devices"
              render={({ field, fieldState: { error } }) => (
                <FormControl>
                  <Autocomplete
                    multiple
                    id="devices"
                    value={selectedDevices}
                    options={devices}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      setSelectedDevices(value);
                    }}
                    renderTags={(value) =>
                      value.map((value, index) => (
                        <Chip
                          variant="outlined"
                          key={index}
                          label={value.name}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("actions.devices")}
                        id="devices"
                        fullWidth
                        rows={4}
                        {...field}
                        error={error !== undefined}
                        helperText={
                          error ? validationLabels.devices[error.type] : ""
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                        }}
                        {...register("devices")}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            {t("common.update")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
