//autocomplete search dialog based on device names

import React from "react";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import toggleSearchDialog from "../../Functions/toggleSearchDialog";
import { useSelector } from "react-redux";

export default function SearchDialog(props) {
  const devices = useSelector((state) => state.devices);

  const handleClose = () => {
    toggleSearchDialog();
  };

  return (
    <Dialog
      title="Search"
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogContent>
        <Autocomplete
          id="search"
          fullWidth
          options={devices && devices.length ? devices : []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Search" />
          )}
          //when a device is selected, redirect to device page
          onChange={(event, value) => {
            if (value !== null) {
              window.location.href = "/device/" + value.id;
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
