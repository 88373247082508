import React from "react";
import { Grid } from "@mui/material";
import TemperatureGuague from "../Guages/Temperature";
import HumidityGuague from "../Guages/Humidity";
import C02PPMGuague from "../Guages/C02PPM";
import TemperatureGraph from "../Graphs/Temperature";
import HumidityGraph from "../Graphs/Humidity";
import C02Graph from "../Graphs/C02";
import LastAlert from "../Cards/LastAlert";
import BatteryStatus from "../Cards/BatteryStatus";
import LastSeen from "../Cards/LastSeen";
import DeviceActionLogs from "../Cards/Alerts";

import FrameList from "../FrameList";
import TVOCGuague from "../Guages/TVOC";
import TVOCGraph from "../Graphs/TVOC";
import PMGraph from "../Graphs/PMGraph";
import PMGuague from "../Guages/PMGuague";
import AQIGuague from "../Guages/AQIGuague";

export default function IndoorAirQualityNBIOT(props) {
  let { isShare } = props;

  return (
    <>
      <Grid
        container
        spacing={1}
        alignItems={"stretch"}
        alignContent={"stretch"}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12} md={isShare ? 6 : 4}>
          <LastSeen />
        </Grid>
        <Grid item xs={12} md={isShare ? 6 : 4}>
          <BatteryStatus />
        </Grid>
        {!isShare && (
          <Grid item xs={12} md={4}>
            <LastAlert />
          </Grid>
        )}
        <Grid item xs={12} md={3} sx={{ my: 1 }}>
          <TemperatureGuague temperatureValue="temp" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ my: 1 }}>
          <HumidityGuague humidityValue="RH" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ my: 1 }}>
          <C02PPMGuague c02Value="CO2" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ my: 1 }}>
          <TVOCGuague tvocValue="TVOC" />
        </Grid>

        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <PMGuague value="PM1_0" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <PMGuague value="PM2_5" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <PMGuague value="PM10" />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mb: 1 }}>
          <AQIGuague value="AQI" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <TemperatureGraph useIsXL={false} temperatureValue="temp" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <HumidityGraph humidityValue="RH" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <C02Graph c02Value="CO2" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <TVOCGraph tvocValue="TVOC" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <PMGraph value="PM1_0" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <PMGraph value="PM2_5" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <PMGraph value="PM10" />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
          <PMGraph value="AQI" />
        </Grid>

        <Grid item xs={12} md={!isShare ? 7 : 12}>
          <FrameList isShare={isShare} />
        </Grid>

        {!isShare && (
          <>
            <Grid item xs={12} md={5}>
              <DeviceActionLogs />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
