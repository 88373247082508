import { CONFIG } from "../config";
import hotspot from "../hotspot.png";

export default function addImages(map) {
  //add image for location history marker
  map.loadImage(
    CONFIG.websiteURL + "/assets/images/device-icon.png",
    function (error, image) {
      if (error) {
        //try localhost:3000 instead of websiteURL
        map.loadImage(
          "http://localhost:3000/assets/images/device-icon.png",
          function (error, image) {
            if (error) throw error;
            map.addImage("marker-15", image);
          }
        );
      } else {
        map.addImage("marker-15", image);
      }
    }
  );

  //add image for hotspot-icon from local source
  map.loadImage(hotspot, (error, image) => {
    if (error) throw error;
    map.addImage("hotspot-icon", image);
  });
}
