import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";
import { postData } from "../../API/Post";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";
import { useSelector } from "react-redux";

export default function ScanQRCodeDialog(props) {
  const { open, handleClose } = props;
  const [scanned, setScanned] = React.useState(false);
  const { t } = useTranslation("common");
  const [organisationID, setOrganisationID] = React.useState();

  const [claim_code, setClaimCode] = React.useState("");

  const organisations = useSelector((state) => state.organisations);

  //if organisations is not empty, set organisationID to first organisation
  useEffect(() => {
    if (organisations && organisations.length) {
      setOrganisationID(organisations[0].id);
    }
  }, [organisations]);

  const updateClaimCode = (contents) => {
    if (!contents || !contents.text) {
      return;
    }

    //set scanned to true
    setScanned(true);
    setClaimCode(contents.text);
  };

  async function claimDevice() {
    let response = await postData("/device/claim", {
      claimCode: claim_code,
      organisation_id: organisationID,
    });

    if (response.response.status === 404) {
      updateSnackbarMessage(
        "Error: QRcode not found. Please try again, if the problem persists, contact support"
      );
    } else if (response.response.status === 200) {
      updateSnackbarMessage("Device claimed successfully");
    }

    handleClose();
  }

  useEffect(() => {
    if (open) {
      setScanned(false);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("common.scan_qr_code")}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t("common.scan_intro")}</DialogContentText>
        {!scanned && (
          <QrReader
            //use rear facing camera
            facingMode="environment"
            constraints={{
              facingMode: "environment",
            }}
            delay={5000}
            onResult={
              //if result is not empty, update claim code
              (result) => {
                if (result) {
                  updateClaimCode(result);
                }
              }
            }
            style={{ width: "100%" }}
          />
        )}

        <FormControl fullWidth>
          <TextField
            id="claim_code"
            label={t("common.claim_code")}
            variant="outlined"
            value={claim_code}
            margin="normal"
          />
        </FormControl>
        <TextField
          id="organisation_id"
          label={t("common.organisation")}
          variant="outlined"
          fullWidth
          select
          margin="normal"
          onChange={(event) => {
            setOrganisationID(event.target.value);
          }}
          value={organisationID}
        >
          {organisations &&
            organisations.length &&
            organisations.map((organisation) => (
              <MenuItem key={organisation.id} value={organisation.id}>
                {organisation.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleClose()}
          color="secondary"
        >
          {t("common.close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => claimDevice()}
          color="primary"
          disabled={!claim_code.length}
        >
          {t("common.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
