import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { postData } from "../../API/Post";
import { fetchOrganisations } from "../../Functions/fetchOrganisations";
import updateSnackbarMessage from "../../Functions/updateSnackbarMessage";

export default function ClaimCodeDialog(props) {
  const { open, handleClose } = props;
  const { t } = useTranslation("common");
  const [claimCode, setClaimCode] = React.useState("");
  const [organisationID, setOrganisationID] = React.useState();
  const [organisations, setOrganisations] = React.useState();

  async function getOrganisations() {
    const response = await fetchOrganisations();
    setOrganisations(response);

    //set organisation id to first organisation
    if (response.length > 0) {
      setOrganisationID(response[0].id);
    }
  }

  async function claimDeviceManually() {
    if (claimCode === "") {
      alert("Please enter a claim code");
      return;
    }

    const response = await postData("/device/claim", {
      claimCode: claimCode,
      organisation_id: organisationID,
    });

    if (Array.isArray(response) && response.length > 0) {
      updateSnackbarMessage("Device claimed successfully");
      handleClose();
    } else {
      alert("Device claim failed");
    }
  }

  useEffect(() => {
    getOrganisations();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("common.enter_claim_code")}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t("common.claim_intro")}</DialogContentText>
        <TextField
          id="claimCode"
          label={t("common.claim_code")}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setClaimCode(event.target.value);
          }}
        />
        <TextField
          id="organisation_id"
          label={t("common.organisation")}
          variant="outlined"
          fullWidth
          select
          margin="normal"
          onChange={(event) => {
            setOrganisationID(event.target.value);
          }}
          value={organisationID}
        >
          {organisations &&
            organisations.length &&
            organisations.map((organisation) => (
              <MenuItem key={organisation.id} value={organisation.id}>
                {organisation.name}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => handleClose()}
          color="secondary"
        >
          {t("common.close")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            claimDeviceManually();
          }}
          color="primary"
        >
          {t("common.enter_claim_code")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
