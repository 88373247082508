import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import convertMvToPercent from "../../Functions/convertMvToPercent";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import convertPercentToColour from "../../Functions/convertPercentToColour";

export default function BatteryStatus(props) {
  const [batteryMv, setBatteryMv] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const device = useSelector((state) => state.device);

  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length > 0) {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);

      let bat = jsonData.voltageMv;

      if (props.field) {
        bat = jsonData[props.field];
      }
      setBatteryMv(bat);
    }
  }, [deviceHistory]);

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Battery Level
          </Typography>
        </Box>
        <Tooltip
          title={`Battery Level: ${convertMvToPercent(
            batteryMv,
            device.device_brand ? device.device_brand : ""
          )}%`}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "1.2rem",
              color: convertPercentToColour(
                convertMvToPercent(batteryMv, device.device_brand)
              ),
            }}
          >
            {convertMvToPercent(batteryMv, device.device_brand)}%
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
