import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useSelector } from "react-redux";
import { formatDate, formatDateOnly } from "../../Functions/formatDate";

export default function PositiveCumulativeGraph(props) {
  const [positiveCumulativeHistory, setPositiveCumulativeHistory] =
    React.useState([]);

  const [positiveCumulativeTotal, setPositiveCumulativeTotal] =
    React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  const theme = useTheme();

  let useIsXL = true;

  if (props.useIsXL !== undefined) {
    useIsXL = props.useIsXL;
  }

  const [isXl, setIsXl] = React.useState(
    useIsXL === true && window.innerWidth > 2048
  );

  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      //grab all data objects from deviceHistory, parse them, and add them to positiveCumulativeHistory
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;

        let positiveCumulative = jsonData.positiveCumulative;

        tempHistory.push({
          Date: time_created,
          PositiveCumulative: positiveCumulative,
        });
      });
      setPositiveCumulativeHistory(tempHistory);

      //work out the difference between the first and last positiveCumulative values
      let firstPositiveCumulative = tempHistory[0].PositiveCumulative;
      let lastPositiveCumulative =
        tempHistory[tempHistory.length - 1].PositiveCumulative;
      let positiveCumulativeTotal =
        lastPositiveCumulative - firstPositiveCumulative;
      setPositiveCumulativeTotal(positiveCumulativeTotal);

      // on reisize, check isXl and update aspect ratio
      const handleResize = () => {
        setIsXl(useIsXL === true && window.innerWidth > 2048);
      };

      window.addEventListener("resize", handleResize);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        title={"Total Usage"}
        titleTypographyProps={{
          variant: "h5",
          color: theme.palette.mode === "light" ? "#000" : "#616161",
        }}
        action={
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            {
              //get last positiveCumulative value and format it with liters per minute
              positiveCumulativeTotal
                ? positiveCumulativeTotal.toFixed(1) + " Liters"
                : "N/A"
            }
          </Typography>
        }
      />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? (isXl ? 3 : 2) : 2}>
          <AreaChart
            data={positiveCumulativeHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <defs>
              <linearGradient
                id="PositiveCumulative"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis dataKey="PositiveCumulative" type="number" yAxisId="1" />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value, name) => {
                return [value + " Liters", name];
              }}
            />
            <Area
              yAxisId="1"
              dataKey="PositiveCumulative"
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#PositiveCumulative)"
              strokeWidth={2}
              dominantBaseline={"middle"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
