import { useTheme } from "@emotion/react";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useEffect } from "react";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { formatDate, formatDateOnly } from "../../../Functions/formatDate";
import { useSelector } from "react-redux";

export default function MotionGraph(props) {
  const [motionHistory, setMotionHistory] = React.useState([]);

  const theme = useTheme();

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const isMobile = useSelector((state) => state.isMobile);
  useEffect(() => {
    if (!deviceHistory || deviceHistory.length === 0) {
      return;
    }

    try {
      let tempHistory = [];
      deviceHistory.forEach((dataObject) => {
        let jsonData = dataObject.data;
        jsonData = JSON.parse(jsonData);
        let time_created = dataObject.time_created;
        let motion = jsonData.motion;

        tempHistory.push({
          Date: time_created,
          motion: motion,
        });
      });
      setMotionHistory(tempHistory);
    } catch (error) {}
  }, [deviceHistory]);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader title="Motion History" />
      <CardContent>
        <ResponsiveContainer aspect={!isMobile ? 7 : 2}>
          <AreaChart
            data={motionHistory}
            margin={{ top: 0, right: 30, left: -15, bottom: 0 }}
          >
            <defs>
              <linearGradient id="Temperature" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={theme.palette.primary.main}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="Date"
              type={"category"} //format date to be more readable
              tickFormatter={(value) => {
                return formatDateOnly(value);
              }}
            />
            <YAxis
              dataKey="motion"
              type="category"
              yAxisId="1"
              scale="point"
              domain={[0, 1]}
              //format label to be yes or no
              tickFormatter={(value) => {
                if (value === 0) {
                  return "No";
                } else {
                  return "Yes";
                }
              }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: theme.palette.background.default,
              }}
              cursor={false}
              labelFormatter={(label) => {
                return formatDate(label);
              }}
              formatter={(value) => {
                return [value === 0 ? "No" : "Yes", "Motion Detected"];
              }}
            />
            <Area
              yAxisId="1"
              type="monotone"
              dataKey="motion"
              //use theme color for line
              stroke={theme.palette.primary.main}
              animationDuration={300}
              fill="url(#Temperature)"
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
