import { store } from "../store";
import fetchActions from "./fetchActions";
import fetchBases from "./fetchBases";
import fetchContacts from "./fetchContacts";
import { fetchDevices } from "./fetchDevices";
import fetchGeofences from "./fetchGeofences";
import { fetchOrganisations } from "./fetchOrganisations";
import { fetchUser } from "./fetchUser";

export default async function refreshData(email, sub) {
  if (!email || !sub) {
    return;
  }

  await fetchUser(email, sub);

  let organisations = await fetchOrganisations();
  let orgID = localStorage.getItem("selectedOrganisationID");

  if (!orgID || orgID === "") {
    orgID = await getFirstOrganisationID(organisations);
    localStorage.setItem("selectedOrganisationID", orgID);
  }

  if (organisations && organisations.length) {
    let selectedOrganisation = {};

    //check if selectedOrganisationID is valid and an option of organisations
    if (organisations.find((org) => org.id === parseInt(orgID)) === undefined) {
      selectedOrganisation = organisations[0];
      localStorage.setItem("selectedOrganisationID", selectedOrganisation.id);
      orgID = selectedOrganisation.id;
    } else {
      selectedOrganisation =
        organisations.find((org) => org.id === parseInt(orgID)) || {};
    }

    store.dispatch({
      type: "selectedOrganisation/update",
      payload: selectedOrganisation,
    });

    fetchDevices(orgID);
    fetchActions(orgID);
    fetchContacts(orgID);
    fetchGeofences(orgID);
    fetchBases(orgID);
  }
}

async function getFirstOrganisationID(organisations) {
  if (organisations && organisations.length) {
    const orgID = organisations[0].id;
    return orgID;
  }

  organisations = await fetchOrganisations();

  if (organisations && organisations.length) {
    const orgID = organisations[0].id;
    return orgID;
  }

  return null;
}
