import React, { useState } from "react";
import { Create, Dns, Input, QrCodeOutlined } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ScanQRCodeDialog from "./ScanQR";
import ClaimCodeDialog from "./claimCode";
import SelfHostDialog from "./selfHost";
import ManualKeysDialog from "./manualKeys";
import { CONFIG } from "../../config";
import { Link } from "react-router-dom";

export default function AddDeviceDialog(props) {
  const [openQrScanner, setOpenQrScanner] = useState(false);
  const [openManual, setOpenManual] = useState(false);
  const [openSelfHost, setOpenSelfHost] = useState(false);
  const [openClaimCode, setOpenClaimCode] = useState(false);

  const { t } = useTranslation("common");

  const { open, handleClose } = props;

  const handleQrScannerClose = () => {
    setOpenQrScanner(false);
  };

  const handleManualClose = () => {
    setOpenManual(false);
    handleClose();
  };

  const handleClaimCodeClose = () => {
    setOpenClaimCode(false);
    handleClose();
  };

  const handleSelfHostClose = () => {
    setOpenSelfHost(false);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>{t("devices.add_device_title")}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenQrScanner(true)}
            startIcon={<QrCodeOutlined />}
          >
            {t("devices.scan_qr_code")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenClaimCode(true)}
            startIcon={<Input />}
          >
            {t("devices.enter_claim_code")}
          </Button>
          {CONFIG.self_host && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenSelfHost(true)}
              startIcon={<Dns />}
            >
              {t("devices.self_host")}
            </Button>
          )}
          {CONFIG.manual_add && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenManual(true)}
              startIcon={<Create />}
            >
              {t("devices.enter_manually")}
            </Button>
          )}
          {CONFIG.siteTitle === "Trackpac" && (
            <>
              <Typography variant="body1" color="text.secondary">
                Looking for device hosting? Check out
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                component={Link}
                to="https://chirpcloud.co.uk"
              >
                ChirpCloud.co.uk
              </Typography>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t("common.cancel")}
        </Button>
      </DialogActions>

      <ScanQRCodeDialog
        open={openQrScanner}
        handleClose={handleQrScannerClose}
      />
      <ClaimCodeDialog
        open={openClaimCode}
        handleClose={handleClaimCodeClose}
      />
      <SelfHostDialog open={openSelfHost} handleClose={handleSelfHostClose} />
      <ManualKeysDialog open={openManual} handleClose={handleManualClose} />
    </Dialog>
  );
}
