//copy of device page with no auth and using the share api to fetch the data

import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { CONFIG } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import NoDeviceFoundDialog from "../components/Dialogs/NoDeviceFound";
import DateTimePickerDialog from "../components/Dialogs/DateTimePicker";
import moment from "moment";
import addHistoryMarkers from "../Functions/addHistoryMarkers";
import addCurrentLocation from "../Functions/addCurrentLocation";
import addPolyline from "../Functions/addPolyline";

import { Container, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { minMaxLngLat } from "../Functions/minMaxLngLat";

import AirQuality from "../components/DeviceTypes/AirQuality";
import TempHumidity from "../components/DeviceTypes/TempHumidity";
import PeopleCounter from "../components/DeviceTypes/PeopleCounter";
import Temperature from "../components/DeviceTypes/Temperature";
import MotionWithTemp from "../components/DeviceTypes/MotionWithTempHumidity";
import DoorWindowWithTemp from "../components/DeviceTypes/DoorWindowWithTemp";
import WaterLeakTempHumidity from "../components/DeviceTypes/WaterLeakTempHumidity";
import AirQualityTVOC from "../components/DeviceTypes/AirQualityTVOC";
import Distance from "../components/DeviceTypes/Distance";
import { store } from "../store";
import isTracker from "../Functions/isTracker";
import setTitle from "../Functions/setTitle";
/*
TODO:

- check if owner of device is paid up, if not, show device inactive warning to let the billing owner know they need to pay.
*/

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default; /* eslint import/no-webpack-loader-syntax: off */

export default function Share(props) {
  const mapRef = useRef(null);
  const [NoDeviceFound, setNoDeviceFound] = useState(false);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().local().subtract(1, "day")
  );
  const [deviceData, setDeviceData] = useState({}); // eslint-disable-line no-unused-vars
  const [endDate, setEndDate] = useState(moment().local().endOf("day"));
  const isMobile = window.innerWidth <= 500;
  const [nonTracker, setNonTracker] = useState(false);
  const [deviceType, setDeviceType] = useState(0);
  const params = useParams();

  const theme = useTheme();

  const updateHistoryInterval = useRef(null);

  async function getDeviceHistory(startDate, endDate) {
    if (startDate === undefined || endDate === undefined) {
      startDate = moment().local().subtract(1, "day").toISOString();
      endDate = moment().local().endOf("day").toISOString();
    }

    startDate = moment(startDate).unix();
    endDate = moment(endDate).unix();

    try {
      //axois get with auth0 token header
      const response = await axios.get(
        CONFIG.API_URL + `/device/share/${params.id}/${startDate}/${endDate}`
      );

      let history = response.data;

      //sort history by time_created ordest to newest
      history.sort((a, b) => {
        return new Date(a.time_created) - new Date(b.time_created);
      });

      //if isTracker device.device_type filter out any history where last_data latitude and longitude are 0
      //remove any history where last_data (encoded json) latitude or longitude is null or 0
      if (isTracker(deviceType)) {
        history = history.filter((point) => {
          let data = point.data;
          data = JSON.parse(data);

          if (data.latitude === null || data.longitude === null) {
            return false;
          }

          if (data.latitude === 0 || data.longitude === 0) {
            return false;
          }

          return true;
        });
      }
      store.dispatch({
        type: "deviceHistory/update",
        payload: history,
      });

      return history;
    } catch (e) {
      if (!e.response) {
        console.log(e);
        return [];
      }
      //if 404 show no device found dialog
      if (e.response.status === 404) {
        setNoDeviceFound(true);
      }
      console.log(e);
      return [];
    }
  }

  async function createMap() {
    let history = await getDeviceHistory();
    let deviceProfile = await axios.get(
      CONFIG.API_URL + `/device/share/profile/${params.id}`
    );

    if (deviceProfile.data) {
      if (deviceProfile.data.profile) {
        deviceProfile = deviceProfile.data.profile;

        if (typeof deviceProfile === "string") {
          deviceProfile = JSON.parse(deviceProfile);
        }
      }
    }

    let coordinates = [];

    history.forEach((point) => {
      let data = point.data;
      data = JSON.parse(data);

      coordinates.push(minMaxLngLat([data.longitude, data.latitude]));
    });

    mapRef.current = new mapboxgl.Map({
      container: "map-share",
      style: CONFIG.mapStyle,
    });

    //add controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), "top-left");
    //add fullscreen
    mapRef.current.addControl(new mapboxgl.FullscreenControl(), "top-left");
    //add scale bottom right
    mapRef.current.addControl(new mapboxgl.ScaleControl(), "bottom-right");

    class DateTimePickerToggle {
      onAdd(map) {
        this._map = map;

        this._btn = document.createElement("button");
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-datePicker";
        this._btn.title = "Select Time Period";
        this._btn.type = "button";
        this._btn["aria-label"] = "Set Date/Time Range";
        this._btn.onclick = function () {
          setOpenDateTimePicker(!openDateTimePicker);
        };

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      }
    }

    const dateTimePickerToggle = new DateTimePickerToggle();

    mapRef.current.addControl(dateTimePickerToggle, "top-left");

    mapRef.current.on("load", () => {
      if (history.length > 0) {
        addPolyline(mapRef.current, history, deviceProfile);
        addHistoryMarkers(mapRef.current, history);
        addCurrentLocation(mapRef.current, history);

        let bounds = new mapboxgl.LngLatBounds();
        coordinates.forEach((point) => {
          bounds.extend(point);
        });

        //fit the map to the bounds
        mapRef.current.fitBounds(bounds, {
          padding: isMobile ? 50 : 20,
          maxZoom: 17,
          essential: true,
          duration: 0,
          animated: false,
        });
      }
    });
  }

  async function updateHistorySource() {
    //get the history
    setOpenDateTimePicker(false);

    let history = await getDeviceHistory(startDate, endDate);

    addPolyline(history, mapRef.current);
    addHistoryMarkers(history, mapRef.current);
    addCurrentLocation(history, mapRef.current);

    let bounds = new mapboxgl.LngLatBounds();

    history.forEach((point) => {
      let data = point.data;
      data = JSON.parse(data);

      bounds.extend(minMaxLngLat([data.longitude, data.latitude]));
    });

    //fit the map to the bounds
    mapRef.current.fitBounds(bounds, {
      padding: isMobile ? 50 : 200,
      maxZoom: 15,
    });
  }

  async function getDevice() {
    try {
      //axois get with auth0 token header
      const response = await axios.get(
        CONFIG.API_URL + `/device/share/info/${params.id}`
      );

      setDeviceData(response.data);

      let resDeviceProfile = await axios.get(
        CONFIG.API_URL + `/device/share/profile/${params.id}`
      );

      if (resDeviceProfile.data) {
        if (resDeviceProfile.data.profile) {
          resDeviceProfile = resDeviceProfile.data.profile;

          console.log(resDeviceProfile);
          if (typeof resDeviceProfile === "string") {
            resDeviceProfile = JSON.parse(resDeviceProfile);
          }

          store.dispatch({
            type: "deviceProfile/update",
            payload: resDeviceProfile,
          });
        }
      }

      setTitle(
        response.data && response.data.name
          ? response.data.name
          : "Share Device"
      );

      setDeviceType(response.data.device_type);

      //if device_type = 1, 4, 7 then create map
      if (isTracker(response.data.device_type)) {
        createMap();
      } else {
        setNonTracker(true);
        getDeviceHistory();
      }
    } catch (e) {
      if (!e.response) {
        console.log(e);
        return [];
      }
      //if 404 show no device found dialog
      if (e.response.status === 404) {
        setNoDeviceFound(true);
      }
      console.log(e);
      return [];
    }
  }

  //use effect to fetch the data
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getDevice();

      //start the interval to update the history every 60 seconds
      updateHistoryInterval.current = setInterval(() => {
        getDeviceHistory();
      }, 60000);
    }

    return () => {
      mounted = false;
      clearInterval(updateHistoryInterval.current);
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      updateHistorySource();
    }
  }, [startDate, endDate]);

  if (nonTracker === false) {
    return (
      <>
        <div id="map-share"></div>
        <NoDeviceFoundDialog open={NoDeviceFound} />
        <DateTimePickerDialog
          open={openDateTimePicker}
          onClose={() => setOpenDateTimePicker(false)}
          trackerStartDate={startDate}
          trackerEndDate={endDate}
          setTrackerStartDate={setStartDate}
          setTrackerEndDate={setEndDate}
        />
      </>
    );
  } else {
    return (
      <Container
        //fullwidth, with theme.pallete.mode aware background color
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#121212" : "#EFEFEF",
          minHeight: "100vh",
          //padding left and right 1
          px: 1,
        }}
        maxWidth="false"
        disableGutters
      >
        <Typography
          variant="h4"
          sx={{ color: theme.palette.mode === "dark" ? "#fff" : "#000", pt: 1 }}
        >
          {deviceData.name}
        </Typography>
        {deviceType === 2 && (
          //air quality
          <AirQuality isShare />
        )}
        {deviceType === 3 && (
          //Temp Humidity
          <TempHumidity isShare />
        )}
        {deviceType === 5 && (
          //Distance Sensor
          <Distance isShare />
        )}
        {deviceType === 6 && (
          //motion sensor with temp and humidity
          <MotionWithTemp isShare />
        )}
        {deviceType === 8 && (
          //pax counter
          <PeopleCounter isShare />
        )}
        {deviceType === 9 && (
          //temperature
          <Temperature isShare />
        )}
        {deviceType === 10 && (
          //motion with temp
          <MotionWithTemp isShare />
        )}
        {deviceType === 11 && (
          //door/window sensor with temp
          <DoorWindowWithTemp isShare />
        )}
        {deviceType === 12 && (
          //water leak with temp humidity
          <WaterLeakTempHumidity isShare />
        )}
        {deviceType === 13 && (
          //air quality tvoc
          <AirQualityTVOC isShare />
        )}
      </Container>
    );
  }
}
