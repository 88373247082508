import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";

export default function OpenCount() {
  const [lastCount, setLastCount] = React.useState(0);

  const deviceHistory = useSelector((state) => state.deviceHistory);
  const theme = useTheme();

  useEffect(() => {
    if (deviceHistory.length > 0) {
      let lastData = deviceHistory[deviceHistory.length - 1];
      let jsonData = lastData.data;
      jsonData = JSON.parse(jsonData);
      setLastCount(jsonData.count);
    }
  }, [deviceHistory]);

  return (
    <Card sx={{ mt: 1 }}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography
            variant="subtitle"
            sx={{
              // if light theme then color is black, else dark grey
              color: theme.palette.mode === "light" ? "#000" : "#616161",
            }}
          >
            Open Count
          </Typography>
        </Box>
        <Tooltip title={`Open Count: ${lastCount}%`}>
          <Typography variant="h5">{lastCount}</Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}
